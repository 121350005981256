import React, {useEffect, } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "./../components/HeaderBanner";
import AboutBanner from "../components/AboutBanner";
import StaticPlaceholder from "./../img/placeholder/static.gif";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {useAppContext} from "../context/Provider";
import {BLOB_STORAGE_URI} from "../constants/Constants";

const useStyles = makeStyles((theme) => createStyles({

    container: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        paddingTop: theme.spacing(4),
        backgroundColor: theme.extendedProperties.background.primary,
    },
    item: {
        height: '100%',
        width: '100%',
        flex: 1,
    },
    title: {
        fontSize: 18,
    },
    aboutImage: {
        height: 200,
        width: 200,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        flexShrink: 0,
},
    aboutText: {
        flexGrow: 1,
        padding: 20,
    },
    aboutContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.background.paper,
        flexWrap: 'nowrap',
        marginBottom: 20,
    },
    categories: {
        width: '100%',
        color: '#cccccc',
        padding: 0,
        margin: 0,
    },
    contentContainer: {
        backgroundColor: theme.extendedProperties.background.primary,
        padding: 25,
        marginTop: 25,
        marginBottom: 25,
        color: theme.palette.primary.textColor
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3)
        },
        textAlign: "center",
        color: theme.palette.primary.textColor,
        backgroundColor: theme.extendedProperties.background.primary //theme.palette.text.secondary
    },
    paperCard: {
        padding: '30px !important',
        textAlign: 'left',
        color: theme.palette.primary.textColor,
        backgroundColor: theme.palette.background.paper,
    },
    stepper: {
        padding: `${theme.spacing(1) * 3}px 0 ${theme.spacing(1) * 5}px`
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    icon: {
        marginRight: theme.spacing(2)
    },
    heroUnit: {
        backgroundColor: theme.palette.background.paper
    },
    heroContent: {
        maxWidth: 600,
        margin: "0 auto",
        padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`
    },
    heroButtons: {
        marginTop: theme.spacing(4)
    },

    layout: {
        width: "auto",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            width: 1100,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    cardGrid: {
        padding: `${theme.spacing(0)}px 0`
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.extendedProperties.foreground.primary
    },
    cardMediaz: {
        padding: 0,
        margin: 0,

        height: 250,
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'tile',
        imageRendering: 'pixelated',
    },
    cardContent: {
        flexGrow: 1,
        typography: {
            color: theme.palette.primary.textColor
        },
        backgroundMedia: `url('${StaticPlaceholder}')`,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6)
    },

    repeatBg: {
        height: 250,
        backgroundRepeat: 'repeat',
        backgroundSize: 'auto',
    },
    slideContainer: {
        height: 200,
        width: 400
    },
    paddedTop: {
        alignContent: 'center',
        paddingTop: theme.spacing(2),
    },
    spaceBanner: {
        background: 'url(../images/stars.png) repeat',
        backgroundAttachment: 'fixed',
        width: '100%',
        height: '400px',
        animation: 'animatedBackground linear 45s infinite',
        overflow: 'hidden',
    }
}));

export default function About() {

    const classes = useStyles();
    const [state, dispatch] = useAppContext();
 
    useEffect(() => {
        async function getRecent() {
            try {

                const response = await fetch(
                    `/api/content/get-list?limit=4`
                );

                const data = await response.json();

                console.log("Loading content:");
                console.log(data);

                dispatch({type: 'SET_CONTENT_LIST', value: data});



            } catch (e) {
                console.error(e);
            }
        }

        dispatch({ type: 'SET_TITLE', value: 'About Business Wintelligence' });
        
        getRecent().then(promise => {  });
    }, []);


    return (
        <React.Fragment>

            <AboutBanner />
            
            <div className={classes.container}>
                <div className={classes.item}>
                    <Grid container justify="center">
                        <Grid item className={classes.innerContainer} xs={9}>
                                                        
                            <div>
                                <Typography variant="h4" component="h1">
                                    This Ain't Some Wordpress Blog, Amigo
                                </Typography>
                                <Typography style={{marginTop: 5}}>
                                    Business wIntelligence runs on custom .NET Core on the back end and a React front end. The editor is built
                                    on Draft.js, the data lives in Azure SQL, search glides on Azure Cognitive Services, images and video
                                    are stored in Azure Blob Storage, and it's served up by Kestrel web server (not IIS). That server is
                                    hosted inside of a Docker container running in a CentOS (Linux) VM in Azure and is accessed through
                                    an Nginx reverse proxy server.
                                    <br/>
                                    <br/>
                                    I would recommend this path to nearly no one when you can go with a one click WordPress implementation in
                                    minutes versus months. I would recommend it to those that love to learn and want to build a blazing fast
                                    experience with no dearth of opportunities to build experience in some of the most impressive technologies
                                    out on the web. 
                                    <br/>
                                    <br/>
                                </Typography>
                                
                            </div>
                            
                            <Typography variant="h4" component="h2" style={{marginBottom: 10}}>
                                Contributors
                            </Typography>
                            
                            <div className={classes.aboutContainer}>
                                <div className={classes.aboutImage}
                                     style={{
                                         backgroundImage: `url("${BLOB_STORAGE_URI}images/about/pedro.jpg")`,
                                     }}>
                                </div>
                                <div className={classes.aboutText}>
                                    <Typography variant="h5" component="h3">
                                        Pedro Salinas-Ruiz
                                    </Typography>
                                    <Typography>
                                        I've been a SQL developer for over a decade, working mostly within the Microsoft BI ecosystem. I've spent 
                                        a lot of time using SSRS, SSIS, SSAS, and Power BI. I work for Microsoft as a SQL BI support engineer.
                                        Business wIntelligence is unaffiliated with Microsoft; any opinions or comments expressed are our own.
                                    </Typography>
                                </div>
                            </div>

                            <div className={classes.aboutContainer}>
                                <div className={classes.aboutImage}
                                     style={{
                                         backgroundImage: `url("${BLOB_STORAGE_URI}images/about/tofu.jpg")`,
                                     }}>
                                </div>
                                <div className={classes.aboutText}>
                                    <Typography variant="h4" component="h1">
                                        Tofu
                                    </Typography>
                                    <Typography>
                                        Tofu is the most useless contributor. He is my cat and he likes to sleep. When he isn't sleeping, he's meowing directly into my face.
                                    </Typography>
                                </div>
                            </div>
                            
                            <div className={classes.aboutContainer}>
                                <div className={classes.aboutImage}
                                      style={{
                                          backgroundImage: `url("${BLOB_STORAGE_URI}images/about/user.jpg")`,
                                      }}>
                                </div>
                                <div className={classes.aboutText}>
                                    <Typography variant="h4" component="h1">
                                        Collaborators
                                    </Typography>
                                    <Typography>
                                        Visitors far and wide are welcome to collaborate. Thanks for helping!
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            

        </React.Fragment>
    );
}
