
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {Route, Routes, useNavigate, useParams} from "react-router";
import {useAppContext} from "../context/Provider";
import {OverlaySpinner} from "../components/spinners/OverlaySpinner";
import { Layout } from "./../components/Layout";
import ContentLoader from "react-content-loader";
import {BLOB_STORAGE_URI} from "../constants/Constants";
import {Link} from "react-router-dom";
import {ContentList} from "../components/ContentList";
import Moment from "react-moment";

const useStyles = makeStyles((theme) => createStyles({
    
    container: {
        marginTop: 10,
        padding: '0px 50px 0px 50px',
        minHeight: 800,
    },
    
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    
    userImage: {
        width: 200,
        height: 200,
        borderRadius: 100,
        backgroundColor: '#cccccc',
        backgroundSize: 'cover',
    },
    
    userBio: {
        flexGrow: 1,
        height: 200,
        marginLeft: 40,
        padding: 40,
    },
    cardGrid: {
        padding: `${theme.spacing(0)}px 0`
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.extendedProperties.foreground.primary
    },
    cardContent: {
        padding: '10px 0px 0px 0px',

    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6)
    },
    cardHeader: {
        color: '#cccccc',
        margin: 0,
        fontSize: 18,
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.linkColor,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    darkLink: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    authorName: {
        fontFamily: theme.typography.headerFontFamily,
        color: '#cdcdcd',
        fontSize: 13,
        textTransform: 'uppercase',
        marginTop: 2,
        textDecoration: 'none',
    },
    authorLink: {
        fontFamily: theme.typography.headerFontFamily,
        color: '#cdcdcd',
        fontSize: 13,
        textTransform: 'uppercase',
        marginTop: 2,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    tags: {
        fontSize: 13,
    },
    conversationButton: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        margin: '10px 0px 10px 0px',
        padding: '10px 0px 10px 0px',
        border: 'solid 1px ' + theme.palette.primary.linkColor,
        color: theme.palette.primary.linkColor,
    },
    cardMedia: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        width: '100%',
        height: 130,
        margin: 0,
        flexShrink: 0,
        border: 'none',
    },
    title: {
        fontSize: 22,
        fontWeight: 400,
        fontFamily: theme.typography.headerFontFamily,
    },
    cardAction: {
        padding: 0,
        textAlign: 'left',
    },
    categories: {
        fontFamily: theme.typography.headerFontFamily,
        width: '100%',
        color: '#cccccc',
        padding: 0,
        margin: 0,
    },
    sideTitle: {
        fontSize: 18,
        fontWeight: 400,
    },
    sideAuthor: {
        fontSize: 14,
        color: '#b2b2b2',
        paddingTop: 4,
    },
    sideDate: {
        fontSize: 14,
        color: '#b2b2b2',
    },
    sideRank: {
        paddingRight: 20,
        paddingTop: 10,
        fontSize: 28,
        color: '#cccccc',
    },
    contentContainer: {
        backgroundColor: theme.extendedProperties.background.primary,
        padding: 25,
        marginTop: 25,
        marginBottom: 25,
        color: theme.palette.primary.textColor
    },
    body: {
        paddingBottom: 50,
    }

}));

const UserLoader = (props) => (
    <ContentLoader
        speed={2}
        width="100%"
        height="auto"
        viewBox="0 0 400 300"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="72" y="3" rx="3" ry="3" width="241" height="15" />
        <rect x="72" y="26" rx="3" ry="3" width="101" height="8" />
        <rect x="7" y="72" rx="3" ry="3" width="410" height="6" />
        <rect x="7" y="88" rx="3" ry="3" width="380" height="6" />
        <rect x="7" y="104" rx="3" ry="3" width="178" height="6" />
        <circle cx="30" cy="30" r="30" />
        <rect x="7" y="124" rx="3" ry="3" width="410" height="6" />
        <rect x="7" y="140" rx="3" ry="3" width="380" height="6" />
        <rect x="7" y="156" rx="3" ry="3" width="178" height="6" />
    </ContentLoader>
)

export const User = () => {

    const [state, dispatch] = useAppContext();
    const classes = useStyles();
    const { userId } = useParams();
    const navigate = useNavigate();
    const test = useParams();
    
    console.log(test);
    return (
        <div className={classes.composeContainerProperties}>
            <OverlaySpinner loading={false}/>
            <Routes>
                <Route path={`:userId`} element={<UserDetail/>}/>
                <Route path={`/`} element={<UserUnknown/>}/>
            </Routes>
        </div>

    );
};

export const UserUnknown = () => {

    const [state, dispatch] = useAppContext();
    const classes = useStyles();
    
    const navigate = useNavigate();

    return (
        <div className={classes.composeContainerProperties}>
           IDK WHO I AM
        </div>

    );
};

export const UserDetail = (props) => {

    const classes = useStyles();
    const { user } = useParams();
    const [userDetails, setUserDetails] = useState({});
    const [loadingDetail, setLoadingDetail] = useState(true);
    const [contentList, setContentList] = useState([]);
    
    useEffect(() => {
        async function getUserDetails() {
            try {
                const response = await fetch(
                    `/api/user/details?id=${user}`
                );
                const data = await response.json();
                setUserDetails(data);

            } catch (e) {
                console.error(e);
            } finally {
                setLoadingDetail(false);
            }
        }

        getUserDetails().then(promise => {
        });
    }, []);
    
    

    console.log(userDetails);
    
    if (loadingDetail) {
        return (
            <Grid item sm={12} md={12} lg={12} className={classes.body}>
                <Grid
                    container
                    spacing={4}
                    className={classes.container}
                    gutterBottom={false}
                >
                    <Grid item sm={12} md={12} lg={12}>
                        <UserLoader width="100%"/>
                        <OverlaySpinner loading={true}/>
                    </Grid>
                </Grid>
            </Grid>
        )
    } else {

        let avatarUri = `${BLOB_STORAGE_URI}/biwin/images/about/user.jpg`;
        
        if (userDetails.avatarSet) {
            avatarUri = `${BLOB_STORAGE_URI}users/${user}/avatar.jpg`;
        }
        
        return (
            <Grid item sm={12} md={12} lg={12} className={classes.body}>
                
                
                <Grid
                    container
                    spacing={4}
                    className={classes.container}
                    gutterBottom={false}
                >
                    <Grid item sm={12} md={12} lg={12}>
                        <div className={classes.headerContainer}>
                            <div className={classes.userImage} style={{backgroundImage: `url(${avatarUri})`}}>
                            </div>
                            <div className={classes.userBio}>
                                <Typography variant="h3" component="h1">
                                    {userDetails.displayName}
                                </Typography>
                                <div style={{fontWeight: '800', fontSize: '1em'}}>Member since <Moment fromNow date={userDetails.createdDtm}/></div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item sm={12} md={12} lg={12}>
                        <Card>
                            <CardContent>
                                <Typography gutterBottom variant="h4" component="h2">
                                    Bio
                                </Typography>
                                <Typography>
                                    {userDetails.bio}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item sm={12} md={12} lg={12} className={classes.recentContent}>
                        <Card>
                            <CardContent>
                                <Typography gutterBottom variant="h4" component="h2">
                                    Recent Content
                                </Typography>

                                <ContentList limit={10} authorId={user} />
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    
                    
                    
                </Grid>
            </Grid>
        )
    }
};
