import type { EditorState } from "./EditorState";
//import type { EditorAction } from "./EditorActions";
import {BLOB_STORAGE_URI} from "../constants/Constants";


export function editorReducer(state: EditorState, action: EditorAction) {

    const { type, value } = action;
    
    // HELLO FROM APP REDUCER
    console.log(action);

    switch (type) {

        case 'SET_EDITOR_STATE':
            return {
                ...state,
                editorState: value
            };
        case 'SET_EDITOR_REF':
            return {
                ...state,
                editorRef: true,
            };
        case 'SET_EDITOR_PLUGINS':
            return {
                ...state,
                plugins: value
            };
        case 'SET_EDITOR_DECORATORS':
            return {
                ...state,
                decorators: value
            };

        case 'SET_EDITOR_UI':
            return {
                ...state,
                editorUi: value
            };
        case 'SET_CONTENT_LOADING':
            return {
                ...state,
                contentLoading: value,
            };
        case 'SET_CONTENT_ID':
            return {
                ...state,
                contentId: value,
            };
        
        case 'SET_BANNER_ID':
            return {
                ...state,
                bannerId: value,
            };
            
        case 'SET_CATEGORIES':
            return {
                ...state,
                categories: value,
            };

        case 'SET_CATEGORY':
            return {
                ...state,
                category: value,
            };

        case 'SET_SUBCATEGORIES':
            return {
                ...state,
                subcategories: value,
            };
            
        case 'SET_SUBCATEGORY':
            return {
                ...state,
                subcategory: value,
            };
        case 'SET_CONTENT_DETAIL':
            return {
                ...state,
                slug: value.slug,
                title: value.title,
                category: value.categoryId,
                subcategory: value.subCategoryId,
                bannerId: value.bannerId,
                bannerUri: BLOB_STORAGE_URI + value.bannerUri
            };
        case 'LOAD_CONTENT':
            if (typeof(value.attachments) === 'undefined') {
                value.attachments = [];
            }
            return {
                ...state,
                slug: value.slug,
                title: value.title,
                category: value.categoryId,
                subcategory: value.subCategoryId,
                editorState: value.editorState,
                attachments: value.attachments,
                bannerId: value.bannerId,
                bannerUri: BLOB_STORAGE_URI + value.bannerUri
            };
        case 'HANDLE_CHANGE':
            return {
                ...state,
                ...value
            };
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}