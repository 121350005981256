import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";


export const LoginPrompt = (props) => {

    const { open, handleClose, handleLogin } = props;
    
    return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="login-dialog-title">Please Login</DialogTitle>
        <DialogContent>
            <DialogContentText>
                You'll need to login to do that. If you don't have an account, registering is easy. All you need is an 
                existing Microsoft account. 
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
            <Button onClick={handleLogin} color="primary">
                Login
            </Button>
        </DialogActions>
    </Dialog>
    );
};