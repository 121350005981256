import {useAppContext} from "../../context/Provider";
import React, {useEffect} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {Link} from "react-router-dom";
import {createStyles, ListItemIcon, ListItemText} from "@material-ui/core";
import Moment from "react-moment";
import {DeleteOutline as DeleteIcon, Publish as PublishIcon} from "@material-ui/icons";
import TableCell from "@material-ui/core/TableCell";
import ListItem from "@material-ui/core/ListItem";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => createStyles({
    table: {
        
    },
    
}));

export const AdministrationUsers = (props) => {

    const [state, dispatch] = useAppContext();

    function GetUsers() {
        let apiUri = '/auth/user-list';
        let options= {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            //body: JSON.stringify(data),
            referrer: 'no-referrer',
        };

        useEffect(() => {
            async function getDrafts() {
                try {
                    dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: true });
                    const response = await fetch(apiUri, options);
                    const data = await response.json();
                    setTableData(data);
                } catch (e) {
                    console.error(e);
                }
                finally {
                    dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: false });
                }
            }
            getDrafts().then(promise => {
            });
        }, []);
    }

    GetUsers();
    const classes = useStyles();
    const [tableData, setTableData] = React.useState([{}]);

    return (
        <React.Fragment>
            <h1>Users</h1>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">UserID</TableCell>
                        <TableCell align="right">Display Name</TableCell>
                        <TableCell align="right">Username</TableCell>
                        <TableCell align="right">E-mail</TableCell>
                        <TableCell align="center">Created Dtm</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell align="left">{row.userId}</TableCell>
                            <TableCell align="right">{row.displayName}</TableCell>
                            <TableCell align="right">{row.username}</TableCell>
                            <TableCell align="right">{row.email}</TableCell>
                            <TableCell align="right"><Moment format="MM/DD/YYYY HH:mm">{row.createdDtm}</Moment></TableCell>
                            <TableCell align="center">
                                <Button>
                                    <DeleteIcon/>
                                </Button>

                                <Button>
                                    <PublishIcon/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </React.Fragment>
    )
};