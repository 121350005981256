import {useAppContext} from "../context/Provider";
import {useEffect} from "react";

export const AuthenticationProvider = (props) => {

    const [state, dispatch] = useAppContext();

    useEffect(() => {
        async function getAuthStatus() {
            try {
                const response = await fetch(
                    '/auth/status'
                );
                const data = await response.json();

                if (data.authenticated) {

                    const now = new Date();
                    const epoch = Math.round(now.getTime() / 1000);

                    const expiration = data.expiration - epoch - 60;
                    console.log('expiration is at: ' + data.expiration);
                    console.log('now is at: ' + epoch);
                    const timer = setTimeout(() => {
                        console.log('You will be logged out in sixty seconds.');
                    }, expiration);

                    dispatch({ type: 'SET_AUTH_STATUS', value: data });
                    
                    return () => clearTimeout(timer);
                }
                else {
                    let authStatus = {
                        authenticated: false,
                    };
                    return (dispatch({ type: 'SET_AUTH_STATUS', value: authStatus }))
                }
            } catch (e) {
                console.error(e);
            }
        }
        getAuthStatus().then(promise => {  });
    }, []);

    return (props.children);
};