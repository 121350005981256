import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";
import {Route, Routes, useNavigate, Link} from 'react-router-dom';
import PropsDebugger from "../components/PropsDebugger";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import ContentLoader from "react-content-loader";
import 'moment-timezone';
import { css } from "@emotion/core";
import PuffLoader from "react-spinners/PuffLoader";
import { ListItemIcon, ListItemText } from '@material-ui/core';
import {useAppContext} from "../context/Provider";

import {
    ColorReviewIcon,
    ColorGlobeIcon,
    ColorUserIcon,
    EditIcon,
    ColorPictureIcon, 
    ColorAddIcon, 
    ColorDocumentIcon, 
    ColorKeyIcon,
    ColorCategoriesIcon,
} from "../icons/Icons";
import {BLOB_STORAGE_URI} from "../constants/Constants";
import {AdministrationMedia} from "./Administration/AdministrationMedia";
import {AdministrationDrafts} from "./Administration/AdministrationDrafts";
import {AdministrationEdit} from "./Administration/AdministrationEdit";
import {AdministrationRoles} from "./Administration/AdministrationRoles";
import {AdministrationCategories} from "./Administration/AdministrationCategories";
import {AdministrationPublished} from "./Administration/AdministrationPublished";
import {AdministrationUsers} from "./Administration/AdministrationUsers";
import {AdministrationReview} from "./Administration/AdministrationReview";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  position: fixed;
  top: 20%;
  left: 50%;
  display: block;
  margin: 0 auto;
  border-color: #0fb28e;
`;

const drawerWidth = 240;

const useStyles = makeStyles((theme) => createStyles({
    drawerPaper: {
        zIndex: theme.zIndex.appBar - 1,
        width: drawerWidth,
        flexShrink: 0,
    },
    composerContainer: {
        position: 'relative',
        margin: 'none',
        padding: 'none',
    },
    fakeDrawer: {
        height: '100%',
        zIndex: theme.zIndex.appBar - 1,
        width: drawerWidth,
        elevation: 5,
        borderRight: 'solid 1px #cccccc',
        //boxShadow: '0 10px 5px rgba(0,0,0,.05)',
        display: 'block',
        flexShrink: 0,
        position: 'absolute',
        left: 0,
        top: 0,
    },
    drawerList: {
        marginTop: theme.spacing(1),
        width: '100%',
        display: 'block',
        float: 'left',
    },
    adaptedLayout: {
        marginLeft: drawerWidth,
        minHeight: "82vh",
    },
    
    
    composeContainerProperties: {
        display: "block",
        overflow: "hidden",
        padding: '10px 40px 10px 40px',

    },
    editorContainer:{
        padding: '20px',
    },
    skinnyHeader: {
        fontWeight: 300,
    },
    icon:{
        marginLeft: 10,
        minWidth: 50,
    },

    
}));

const AdministrationModule = (props) => {
 
    return (
        <div>
        "Are you lost? {props.match.params.module} doesn't exist."
            <PropsDebugger props={props}/>
        </div>
    )
};

const AdministrationNew = (props) => {

    const [open, setOpen] = useState(true);
    const [title, setTitle] = useState('');
    const [state, dispatch] = useAppContext();
    const navigate = useNavigate();
    
    function handleClose() {
        setOpen(false);
    }
    
    const HandleCreate = () => {
        
        let apiUri = "/api/content/create?title=" + title;
        let options= {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            //body: JSON.stringify(data),
            referrer: 'no-referrer',
        };

        dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: true });
        
        fetch(apiUri, options)
            .then(data => data.json())
            .then((data) => {
                console.log("Well this was returned: ");
                console.log(data);
                navigate(`/administration/edit/${data}`);
            })
            .catch(error => {
                console.error('Error:', error)
            })
            .finally(info => {
                dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: false });
            })
    }
    
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Create New</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter a name for the new content item.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="new-content-title"
                    type="text"
                    fullWidth
                    onChange={e => setTitle(e.target.value)}
                    value={title}
                />
            </DialogContent>
            <DialogActions> 
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={HandleCreate} color="primary">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export const ContentLoading = (props) => {
    return (
    <ContentLoader>
    </ContentLoader>
    )
};

export default function Administration () {
    
    const [state, dispatch] = useAppContext();
    const classes = useStyles();
    const navigate = useNavigate();
    
    //dispatch({ type: 'SET_TITLE', value: 'Business Wintelligence Administration' });
    
    return (
            <React.Fragment>
                <div className={classes.composerContainer}>
                    <div
                        variant="permanent"
                        className={classes.fakeDrawer}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div className={classes.drawerList}>
                            <List>
                                <ListItem button component="a" onClick={() => navigate('/administration/media/')}>
                                    <ListItemIcon className={classes.icon}><ColorPictureIcon size={24}/></ListItemIcon>
                                    <ListItemText primary="Media" />
                                </ListItem>
                                <ListItem button component="a" onClick={() => navigate('/administration/new/')}>
                                    <ListItemIcon className={classes.icon}><ColorAddIcon size={24}/></ListItemIcon>
                                    <ListItemText primary="New" />
                                </ListItem>
                                <ListItem button component="a" onClick={() => navigate('/administration/drafts/')}>
                                    <ListItemIcon className={classes.icon}><ColorDocumentIcon size={24}/></ListItemIcon>
                                    <ListItemText primary="Drafts" />
                                </ListItem>
                                <ListItem button component="a" onClick={() => navigate('/administration/review/')}>
                                    <ListItemIcon className={classes.icon}><ColorReviewIcon size={24}/></ListItemIcon>
                                    <ListItemText primary="Review" />
                                </ListItem>
                                <ListItem button component="a" onClick={() => navigate('/administration/published/')}>
                                    <ListItemIcon className={classes.icon}><ColorGlobeIcon size={24}/></ListItemIcon>
                                    <ListItemText primary="Published" />
                                </ListItem>
                                <ListItem button component="a" onClick={() => navigate('/administration/users/')}>
                                    <ListItemIcon className={classes.icon}><ColorUserIcon size={24}/></ListItemIcon>
                                    <ListItemText primary="Users" />
                                </ListItem>
                                <ListItem button component="a" onClick={() => navigate('/administration/roles/')}>
                                    <ListItemIcon className={classes.icon}><ColorKeyIcon size={24}/></ListItemIcon>
                                    <ListItemText primary="Roles" />
                                </ListItem>
                                <ListItem button component="a" onClick={() => navigate('/administration/categories/')}>
                                    <ListItemIcon className={classes.icon}><ColorCategoriesIcon size={24}/></ListItemIcon>
                                    <ListItemText primary="Categories" />
                                </ListItem>
                            </List>
                        </div>
                    </div>
                    <div className={classes.adaptedLayout}>
                        <div className={classes.composeContainerProperties}>
                            <PuffLoader
                                css={override}
                                size={200}
                                color={"#0fb28e"}
                                loading={state.administrationLoading}
                            />
                            <Routes>
                                <Route path={`media`} element={<AdministrationMedia/>}/>
                                <Route path={`new`} element={<AdministrationNew/>}/>
                                <Route path={`drafts`} element={<AdministrationDrafts/>}/>
                                <Route path={`edit/:id`} element={<AdministrationEdit/>}/>
                                <Route path={`published`} element={<AdministrationPublished/>}/>
                                <Route path={`review`} element={<AdministrationReview/>}/>
                                <Route path={`roles`} element={<AdministrationRoles/>}/>
                                <Route path={`users`} element={<AdministrationUsers/>}/>
                                <Route path={`categories`} element={<AdministrationCategories/>}/>
                                <Route path={`:module`} element={<AdministrationModule/>}/>
                            </Routes>
                        </div>
                    </div>
                </div>
            </React.Fragment>

    );
}


