
import React from "react";
import Grid from "@material-ui/core/Grid";
import StaticPlaceholder from "../img/placeholder/static.gif";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import WebFont from 'webfontloader';

WebFont.load({
    google: {
        families: ['Material Icons']
    }
});

const useStyles = makeStyles((theme) => createStyles({
    
    contentContainer: {
        backgroundColor: theme.extendedProperties.background.primary,
        padding: 25,
        marginTop: 25,
        marginBottom: 25,
        color: theme.palette.primary.textColor
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3)
        },
        textAlign: "center",
        color: theme.palette.primary.textColor,
        backgroundColor: theme.extendedProperties.background.primary, //theme.palette.text.secondary
    },
    stepper: {
        padding: `${theme.spacing(1) * 3}px 0 ${theme.spacing(1) * 5}px`
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    icon: {
        marginRight: theme.spacing(2)
    },
    heroUnit: {
        backgroundColor: theme.palette.background.paper
    },
    heroContent: {
        maxWidth: 600,
        margin: "0 auto",
        padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`
    },
    heroButtons: {
        marginTop: theme.spacing(4)
    },

    layout: {
        width: "auto",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            width: 1100,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },

    whitespace: {
        width: 'auto',
        background: '#ffffff',
        padding: '0 0 100 0',
    },
    cardGrid: {
        padding: `${theme.spacing(0)}px 0`
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.extendedProperties.foreground.primary,
    },
    cardMediaz: {
        padding: 0,
        margin: 0,
        height: 250,
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'tile',
        imageRendering: 'pixelated',
    },
    cardContent: {
        flexGrow: 1,
        typography: {
            color: theme.palette.primary.textColor
        },
        backgroundMedia: `url('${StaticPlaceholder}')`,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6)
    },

    repeatBg: {
        height: 250,
        backgroundRepeat: 'repeat',
        backgroundSize: 'auto',
    },
    slideContainer: {
        height: 200,
        width: 400
    },
    paddedTop: {
        alignContent: 'center',
        paddingTop: theme.spacing(2),
    },
    spaceBanner: {
        background: 'url(../images/stars.png) repeat',
        backgroundAttachment: 'fixed',
        width: '100%',
        height: '400px',
        animation: 'animatedBackground linear 45s infinite',
        overflow: 'hidden',
    },
    search: {
        width: '100%',
    },
    gutter: {
        marginTop: 20,
        paddingTop: 50,
    }
}));

const cards = [1, 2, 3, 4];

export default function Tools() {

    const classes = useStyles();

    return (
        <React.Fragment>

            <Grid
                container
                spacing={4}
                style={{'marginTop': 10, 'padding': '0px 50px 0px 50px'}}
            >
                <Grid item sm={12} md={12} lg={12}>
                    <MaterialTable
                        title="Business Intelligence Tools We Like"
                        columns={[
                            { title: 'Tool Name', field: 'name' },
                            { title: 'Description', field: 'description' },
                            { title: 'Link', field: 'link', render: rowData => (<a target="_blank" href={rowData.link}><span className="material-icons">link</span></a>)}
                            /*
                            {
                                title: 'Birth Place',
                                field: 'birthCity',
                                lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
                            },
                             */
                        ]}
                        data={[
                            { name: 'Microsoft Network Monitor', description: 'The Network Monitor tool (NetMon.exe) is a Windows-based application that you can use to view traces from WPD components. The tool replaces WpdMon.exe and provides a new means of collecting and viewing WPD traces in Windows 8.', link: 'https://www.microsoft.com/en-us/download/details.aspx?id=4865' },
                            { name: 'Fiddler', description: 'Fiddler is a web debugging tool which logs all HTTP(S) traffic between your computer and the internet. Inspect traffic, set breakpoints, and fiddle with incoming and outgoing data.', link: 'https://www.telerik.com/download/fiddler' },
                            {name: 'Quicktype', description: 'quicktype generates strongly-typed models and serializers from JSON, JSON Schema, TypeScript, and GraphQL queries, making it a breeze to work with JSON type-safely in many programming languages.', link: 'https://quicktype.io/csharp/'},
                            {name: 'Visual Studio Code', description: 'At its heart, Visual Studio Code features a lightning fast source code editor, perfect for day-to-day use. With support for hundreds of languages, VS Code helps you be instantly productive with syntax highlighting, bracket-matching, auto-indentation, box-selection, snippets, and more. Intuitive keyboard shortcuts, easy customization and community-contributed keyboard shortcut mappings let you navigate your code with ease.', link: 'https://code.visualstudio.com/'},
                            {name: 'Docker', description: 'Docker Desktop is an application for MacOS and Windows machines for the building and sharing of containerized applications and microservices.', link: 'https://www.docker.com/products/docker-desktop'},
                            {name: 'Power BI Desktop', description: 'Power BI Desktop is a free application you install on your local computer that lets you connect to, transform, and visualize your data. With Power BI Desktop, you can connect to multiple different sources of data, and combine them (often called modeling) into a data model. This data model lets you build visuals, and collections of visuals you can share as reports, with other people inside your organization. Most users who work on business intelligence projects use Power BI Desktop to create reports, and then use the Power BI service to share their reports with others.', link: 'https://powerbi.microsoft.com/en-us/desktop/'},
                            {name: 'Color Themes for Visual Studio', description: 'A collection of themes for Visual Studio that can be used in conjunction with Color Theme Editor for Visual Studio.', link: 'https://marketplace.visualstudio.com/items?itemName=VisualStudioPlatformTeam.ColorThemesforVisualStudio'},
                            {name: 'Color Theme Editor for Visual Studio 2017', description: 'Allows users to customize the Visual Studio 2017 color palette.', link: 'https://marketplace.visualstudio.com/items?itemName=VisualStudioPlatformTeam.VisualStudio2017ColorThemeEditor'},
                            {name: 'Color Theme Editor for Visual Studio 2019', description: 'Allows users to customize the Visual Studio 2019 color palette.', link: 'https://marketplace.visualstudio.com/items?itemName=VisualStudioPlatformTeam.VisualStudio2019ColorThemeEditor'},
                            {name: 'VS Code Themes', description: 'Visual Studio Code is a code editor redefined and optimized for building and debugging modern web and cloud applications. It includes support for development operations like debugging, task running, and version control. It aims to provide just the tools a developer needs for a quick code-build-debug cycle and leaves more complex workflows to fuller featured IDEs, such as Visual Studio IDE.', link: 'https://vscodethemes.com/'},
                            {name: 'Remote Desktop Manager', description: 'Remote Desktop Manager (RDM) centralizes all remote connections on a single platform that is securely shared between users and across the entire team. With support for hundreds of integrated technologies — including multiple protocols and VPNs — along with built-in enterprise-grade password management tools, global and granular-level access controls, and robust mobile apps to complement desktop clients for Windows and Mac, RDM is a Swiss Army knife for remote access. RDM empowers IT departments to drive security, speed and productivity throughout the organization, while reducing inefficiency, cost and risk.', link: 'https://remotedesktopmanager.com/home/downloadfree'},
                            {name: 'Hyper', description: 'Hyper is an Electron-based terminal built on HTML/CSS/JS. It is fully extensible.', link: 'https://hyper.is/'},
                            
                        ]}
                        options={{
                            filtering: true,
                            pageSize: 20,
                        }}
                    />
                </Grid>
            </Grid>
            <br/>
            <br/>
        </React.Fragment>
    );
}
