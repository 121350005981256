import React, {useEffect, useState} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "./../components/HeaderBanner";
import { Layout } from "../components/Layout";
import { HeaderBanner } from "../components/HeaderBanner";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {AppProvider, useAppState, useAppDispatch, useAppContext} from "../context/Provider";
import Moment from 'react-moment';
import 'moment-timezone';
import ContentLoader from 'react-content-loader';
import {Avatar, AvatarDetails} from '../components/Avatar';
import ButtonBase from "@material-ui/core/ButtonBase";
import {Link, useNavigate} from 'react-router-dom';
import {plugins} from "../editor/plugins";
import decorators from "../editor/decorator";
import Editor from "draft-js-plugins-editor";
import {BLOB_STORAGE_URI} from './../constants/Constants';

import {convertFromRaw, EditorState, getDefaultKeyBinding, Modifier, RichUtils} from "draft-js";
import {ContentList} from "../components/ContentList";
import {PopularList} from "../components/PopularContent";

const useStyles = makeStyles((theme) => createStyles({
    
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.linkColor,
    },
    tags: {
        fontSize: 13,
    },
    conversationButton: {
        display: 'flex', 
        alignContent: 'center', 
        justifyContent: 'center', 
        margin: '10px 0px 10px 0px', 
        padding: '10px 0px 10px 0px', 
        border: 'solid 1px ' + theme.palette.primary.linkColor, 
        color: theme.palette.primary.linkColor,
    },
    cardMedia: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        width: '100%',
        height: 130,
        margin: 0,
        flexShrink: 0,
        border: 'none',
    },
    cardAction: {
        padding: 0,
        textAlign: 'left',
    },
    categories: {
        fontFamily: theme.typography.headerFontFamily,
        width: '100%',
        color: '#cccccc',
        padding: 0,
        margin: 0,
    },
    sideTitle: {
        fontSize: 18,
        fontWeight: 400,
    },
    sideAuthor: {
        fontSize: 14,
        color: '#b2b2b2',
        paddingTop: 4,
    },
    sideDate: {
        fontSize: 14,
        color: '#b2b2b2',
    },
    sideRank: {
        paddingRight: 20, 
        paddingTop: 10, 
        fontSize: 28,
        color: '#cccccc',
    },
    contentContainer: {
        backgroundColor: theme.extendedProperties.background.primary,
        padding: 25,
        marginTop: 25,
        marginBottom: 25,
        color: theme.palette.primary.textColor
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3)
    },
    textAlign: "center",
        color: theme.palette.primary.textColor,
        backgroundColor: theme.extendedProperties.background.primary //theme.palette.text.secondary
    },
    stepper: {
        padding: `${theme.spacing(1) * 3}px 0 ${theme.spacing(1) * 5}px`
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
        button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
        icon: {
        marginRight: theme.spacing(2)
    },
        heroUnit: {
        backgroundColor: theme.palette.background.paper
    },
        heroContent: {
        maxWidth: 600,
        margin: "0 auto",
        padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`
    },
    heroButtons: {
        marginTop: theme.spacing(4)
    },
    layout: {
        width: "auto",
        marginTop: 0,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1200 + theme.spacing(3) * 2)]: {
          width: 1200,
          marginLeft: "auto",
          marginRight: "auto"
        },
        minHeight: '90vh',
        paddingTop: 10,
    },
    cardGrid: {
        padding: `${theme.spacing(0)}px 0`
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.extendedProperties.foreground.primary
    },
    cardContent: {
        padding: '10px 0px 0px 0px',
        
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6)
        },
    cardHeader: {
        color: '#cccccc',
        margin: 0,
        fontSize: 18,
    },
    slideContainer: {
        height: 200,
        width: 400
    },
    spaceBanner: {
        background: 'url(../images/stars.png) repeat',
        backgroundAttachment: 'fixed',
        width: '100%',
        height: '400px',
        animation: 'animatedBackground linear 45s infinite',
        overflow: 'hidden',
    },

}));



export default function Home() {

    const classes = useStyles();
    const [state, dispatch] = useAppContext();
    const [news, setNews] = useState([]);

    const [contentData, setContentData] = useState(EditorState.createEmpty());
    const [contentDetails, setContentDetails] = useState({
        title: '',
        author: '',
        createdDtm: '',
        categoryId: 1,
        subCategoryId: 1,
    });

    const getBlockStyle = (block) => {
        switch (block.getType()) {
            case 'code-block':
                return 'language-sql';
            default:
                return null;
        }
    };

    const handleChange = (newEditorState) => {
        const selection = newEditorState.getSelection();
        const block = newEditorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey());
        const currentContent = contentData.getCurrentContent();
        const newContent = newEditorState.getCurrentContent();
        if (
            block.getType() === 'code-block' &&
            !newContent.equals(currentContent)
        ) {
            const data = block.getData().merge({ language: 'sql' });
            const newBlock = block.merge({ data });
            const newContentState = newEditorState.getCurrentContent().merge({
                blockMap: newEditorState
                    .getCurrentContent()
                    .getBlockMap()
                    .set(selection.getStartKey(), newBlock),
                selectionAfter: selection
            });
            (
                setContentData(EditorState.push(newEditorState, newContentState, 'change-block-data'))
            );
        } else (
            setContentData(newEditorState)
        );
    };
    
    useEffect(() => {

        async function getNews() {
            try {
                const response = await fetch(
                    `/api/content/news`
                );
                const data = await response.json();

                if (data.itemContent) {
                    data.editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(data.itemContent)));

                    setContentData(data.editorState);
                    setContentDetails({
                        title: data.title,
                        authorDisplayName: data.authorDisplayName,
                        createdDtm: data.createdDtm,
                        publishedDtm: data.publishedDtm,
                        category: data.category,
                        subCategory: data.subCategory,
                        authorHash: data.authorHash,
                        bannerUri: BLOB_STORAGE_URI + data.bannerUri,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }

        dispatch({type: 'SET_TITLE', value: "Business wIntelligence - Home of Microsoft BI Nerds"});
        
        getNews().then(promise => {        });
        
    }, []);
    
    const NewsLoader = () => {
        
        return (
            <RecentNews/>
        )
    };
    
    const RecentNews = () => {
        console.log("content data: ")
        console.log(contentData)
        return (
            <React.Fragment>
                {news.map((news, index) => (
                    <Card key={index} className={classes.card}>
                        <div className={classes.cardHeader}>
                            Recent Updates
                        </div>
                        <CardContent className={classes.cardContent}>
                            <div style={{margin: '0px 0px 15px 0px'}}>
                                <Typography variant="h5" component="h5" className={classes.title}>{news.title}</Typography>
                            </div>
                            <div style={{display: 'flex', alignContent: 'center',}}>
                                <div style={{height: 64, display: 'flex', flexDirection: 'column', justifyContent: 'center',}}>
                                    <Avatar size={56} hash={news.authorHash}/>
                                </div>
                                <AvatarDetails createdDtm={news.publishedDtm} author={news.authorDisplayName}/>
                            </div>
                            <Grid item sm={12} md={12} lg={12}>

                                <Editor
                                    className={classes.editor}
                                    //onChange={handleChange}
                                    editorState={contentData}
                                    //plugins={[...plugins]}
                                    readOnly={true}
                                    //decorators={decorators}
                                    //blockStyleFn={getBlockStyle}
                                />
                                
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <div id="post-tags" style={{borderTop: 'solid 1px #efefef', borderBottom: 'solid 1px #efefef', padding: '20px 0px 20px 0px', margin: '20px 0px 20px 0px'}}>
                                    Author section
                                </div>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <div id="post-tags" className={classes.tags} style={{padding: '0px 0px 20px 0px',}}>
                                    <span style={{borderRadius: 3, backgroundColor: '#efefef', padding: 5, color: '#9f9f9f'}}>news</span>
                                </div>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <div className={classes.conversationButton}>
                                    See Responses (0)
                                </div>
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
            </React.Fragment>
        )
    };
    
    return (
        <React.Fragment>
            <HeaderBanner />
            
            <Layout
                suppressPadding={true}
                >
                
                <Grid
                    container
                    spacing={4}
                    className={classes.layout}
                >
                    <ContentList limit={14}/>
                    
                    <Grid item sm={12} md={9} lg={9}>
                        <NewsLoader/>
                    </Grid>

                    <Grid item sm={12} md={3} lg={3}>
                        
                    </Grid>
                </Grid>
            </Layout>
        </React.Fragment>
    );
}
