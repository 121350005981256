import Draft, {
    convertToRaw,
    convertFromRaw,
    ContentState,
    EditorState as ReactEditorState,
} from "draft-js";

import React from "react";


export type EditorState = {
    editorState: string,
    contentId: number,
    editorRef: object,
    isSubmitting: boolean,
    categories: [],
    subcategories: [],
    category: number,
    subcategory: number,
    slug: string,
    title: string,
    attachments: [],
    bannerId: number,
    bannerUri: string,
    contentLoading: boolean,
    plugins: []
}

export const editorInitialState = {
    editorState: ReactEditorState.createEmpty(),
    editorStateRaw: '',
    contentId: '',
    editorRef: null,
    isSubmitting: false,
    categories: [{undefined}],
    subcategories: [{undefined}],
    category: '',
    subcategory: '',
    slug: '',
    title: '',
    attachments: [{undefined}],
    bannerId: -1,
    bannerUri: '',
    plugins: [],
    decorators: [],
    editorUi: {},
    contentLoading: false,
};