import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import './../components/HeaderBanner';
import {createStyles, makeStyles, withStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {useAppContext} from "../context/Provider";
import Button from "@material-ui/core/Button";

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const useStyles = makeStyles((theme) => createStyles({
    footerContainer:{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.fadedText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(6),
    },
    artificialPadding: {
        backgroundColor: theme.extendedProperties.background.primary,
    },
    contentContainer: {

    },

    gridItem: {

    },

    footer: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),

    },
}));

export const Footer = (props) => {

    const [state, dispatch] = useAppContext();
    const classes = useStyles();
    
    return (
        <React.Fragment>
            <Grid container >
            </Grid>

            <Grid container className={classes.footerContainer} alignItems="center" alignContent="center" justify="center" spacing={0}>
                <Grid item xs={11} sm={8}>

                    <Grid container spacing={4} className={classes.contentContainer}>

                        <Grid item xs={4} className={classes.gridItem}>
                            
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                           
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                            
                        </Grid>

                        <Grid item xs={12} className={classes.gridItem} style={{textAlign: 'center',}}>
                            © 2020 Pedro Salinas-Ruiz. All rights reserved.
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </React.Fragment>
    )
};