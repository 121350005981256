import { Article, Home, Content, Articles, Tools, User, NotFound, Administration, WebEditor, Lab, Community, About, Search } from './../views';
import React from "react";

export const DIRECTORY_MAIN = [
    {
        id: 1,
        title: 'Home',
        slug: '',
        component: <Home/>,
        showMenu: false,
    },
    {
        id: 2,
        title: 'Content',
        description: 'A REST API that I built from scratch with GET and POST requests!',
        slug: 'content',
        component: <Content/>,
        showMenu: true,
        submenu: [
            {
                id: 1,
                title: 'Find a Guild',
            },
            {
                id: 2,
                title: 'Create a Guild',
            }
        ]
    },
    {
        id: 4,
        title: 'Tools',
        description: 'A REST API that I built from scratch with GET and POST requests!',
        slug: 'tools',
        component: <Tools/>,
        showMenu: true,
        requiredAction: 'admin_panel_access',
    },
    {
        id: 5,
        title: 'Community',
        description: 'A REST API that I built from scratch with GET and POST requests!',
        slug: 'community',
        component: <Community/>,
        showMenu: true,
        requiredAction: 'admin_panel_access',
    },
    {
        id: 6,
        title: 'About',
        description: 'A REST API that I built from scratch with GET and POST requests!',
        slug: 'about',
        component: <About/>,
        showMenu: true,
    },
    {
        id: 6,
        title: 'Lab',
        description: 'A REST API that I built from scratch with GET and POST requests!',
        slug: 'lab',
        component: <Lab/>,
        showMenu: true,
        requiredAction: 'admin_panel_access',
    },
    {
        id: 8,
        title: 'Article',
        description: 'A REST API that I built from scratch with GET and POST requests!',
        slug: 'article/:articleId',
        component: <Article/>,
        showMenu: false,
    },
    {
        id: 97,
        title: 'NotFound',
        slug: 'not-found',
        component: <NotFound/>,
        showMenu: false,
    },
    {
        id: 95,
        title: 'Search',
        description: 'A REST API that I built from scratch with GET and POST requests!',
        slug: 'search/:q',
        component: <Search/>,
        showMenu: false,
    },
    {
        id: 96,
        title: 'User',
        description: 'A REST API that I built from scratch with GET and POST requests!',
        slug: 'user/:user',
        component: <User/>,
        showMenu: false,
    },
    {
        id: 98,
        title: 'Process Sign-In',
        description: 'A REST API that I built from scratch with GET and POST requests!',
        slug: 'signin-microsoft',
        component: <Home/>,
        showMenu: false,
    },
    {
        id: 99,
        title: 'Administration',
        description: 'A REST API that I built from scratch with GET and POST requests!',
        slug: 'administration',
        requiredAction: 'admin_panel_access',
        component: <Administration/>,
        showMenu: true,
    },
    
];

export default null;
