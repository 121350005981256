import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';


import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';

import { Formik } from 'formik';

import * as Yup from 'yup';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        flexBasis: 200,
    },
    registerField: {
        paddingBottom: 10,
    }
});

class FormSignUp extends React.Component {
    
    state = {
        showPassword: false,
    };

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    render() {
        
        const { classes } = this.props;
        const initialState = this.props.formData;
        const validationSchema = Yup.object({
            title: Yup.string("Enter a title")
                .required("An article title is required."),
        });
        
        return (
            <Grid container
                  spacing={3}
                  justify="center"
                  alignItems="center"
                  alignContent="center">

                <Grid item xs={12} style={{textAlign: 'center', paddingTop: 20}}>
                    
                    <Formik
                        composeType={this.props.composeType}
                        initialValues={initialState}
                        validationSchema={validationSchema}
                        onSubmit={(values, actions) => {
                            this.handleSubmit(values, actions);
                        }}
                    >
                        {props => {

                            const { classes } = this.props;
                            return (
                                <React.Fragment>
                                    <TextField
                                        fullWidth
                                        label="E-mail Address"
                                        className={classes.registerField}
                                    />
                
                                    <TextField
                                        fullWidth
                                        label="Display Name"
                                        className={classes.registerField}
                                    />
                
                                    <TextField
                                        fullWidth
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        label="Password"
                                        value={this.state.password}
                                        onChange={this.handleChange('password')}
                                        className={classes.registerField}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Toggle password visibility"
                                                        onClick={this.handleClickShowPassword}
                                                    >
                                                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    
                                    <Grid item xs={12} style={{textAlign: 'center', paddingTop: 10}}>
                                        <Typography>
                                            By clicking Sign Up, you are indicating that you have read and agree to our <a href="www.google.com">Terms of Service.</a>
                                        </Typography>
                                    </Grid>
                    
                                    <Grid item xs={12} style={{textAlign: 'center', paddingTop: 20, paddingBottom: 40,}}>
                                        <Button variant="contained" onClick={this.handleClose} color="primary" fullWidth>
                                            Sign Up
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                            )
                        }}
                     </Formik>
                </Grid>
            </Grid>
        );
    }
}

FormSignUp.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormSignUp);