import React, { useReducer, useContext } from "react";
import { appReducer } from "./Reducers";
import { initialState } from "./State";
import { Action } from "./Actions";

type Dispatch = (action: Action) => void

type AppProviderProps = {children: React.ReactNode}

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

// PROVIDER
function AppProvider({children}: AppProviderProps) {
    
    const [state, dispatch] = useReducer(appReducer, initialState);
    
    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                {children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    )
}

function useAppState() {
    const context = useContext(AppStateContext);
    if (context === undefined) {
        throw new Error('useAppState must be used within a AppProvider')
    }
    return context
}

function useAppDispatch() {
    const context = useContext(AppDispatchContext);
    if (context === undefined) {
        throw new Error('useAppDispatch must be used within a AppProvider')
    }
    return context
}

function useAppContext() {
    return [useAppState(AppStateContext), useAppDispatch(AppDispatchContext)]
}

export {AppProvider, useAppState, useAppDispatch, useAppContext}