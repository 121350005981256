
import React from "react";
import Grid from "@material-ui/core/Grid";
import StaticPlaceholder from "../img/placeholder/static.gif";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
    contentContainer: {
        backgroundColor: theme.extendedProperties.background.primary,
        padding: 25,
        marginTop: 25,
        marginBottom: 25,
        color: theme.palette.primary.textColor
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3)
        },
        textAlign: "center",
        color: theme.palette.primary.textColor,
        backgroundColor: theme.extendedProperties.background.primary, //theme.palette.text.secondary
    },
    stepper: {
        padding: `${theme.spacing(1) * 3}px 0 ${theme.spacing(1) * 5}px`
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    icon: {
        marginRight: theme.spacing(2)
    },
    heroUnit: {
        backgroundColor: theme.palette.background.paper
    },
    heroContent: {
        maxWidth: 600,
        margin: "0 auto",
        padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`
    },
    heroButtons: {
        marginTop: theme.spacing(4)
    },

    layout: {
        width: "auto",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            width: 1100,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },

    whitespace: {
        width: 'auto',
        background: '#ffffff',
        padding: '0 0 100 0',
    },
    cardGrid: {
        padding: `${theme.spacing(0)}px 0`
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.extendedProperties.foreground.primary,
    },
    cardMediaz: {
        padding: 0,
        margin: 0,
        height: 250,
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'tile',
        imageRendering: 'pixelated',
    },
    cardContent: {
        flexGrow: 1,
        typography: {
            color: theme.palette.primary.textColor
        },
        backgroundMedia: `url('${StaticPlaceholder}')`,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6)
    },

    repeatBg: {
        height: 250,
        backgroundRepeat: 'repeat',
        backgroundSize: 'auto',
    },
    slideContainer: {
        height: 200,
        width: 400
    },
    paddedTop: {
        alignContent: 'center',
        paddingTop: theme.spacing(2),
    },
    spaceBanner: {
        background: 'url(../images/stars.png) repeat',
        backgroundAttachment: 'fixed',
        width: '100%',
        height: '400px',
        animation: 'animatedBackground linear 45s infinite',
        overflow: 'hidden',
    },
    search: {
        width: '100%',
    },
    gutter: {
        marginTop: 20,
        paddingTop: 50, 
    }
}));

const cards = [1, 2, 3, 4];

export default function Articles() {

    const classes = useStyles();
    
    return (
        <React.Fragment>

            <Grid
                container
                spacing={4}
                style={{'marginTop': 10, 'padding': '0px 50px 0px 50px'}}
            >
                {cards.map(card => (
                    <Grid item key={card} sm={6} md={4} lg={3}>
                        
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                title="Image title"
                                image={StaticPlaceholder}
                                classes={{root: classes.repeatBg}}
                                component="div"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Heading
                                </Typography>
                                <Typography>
                                    This is a media card. You can use this section to describe
                                    the content.
                                </Typography>
                               
                            </CardContent>
                            <CardActions>
                                <Button size="small" color="secondary">
                                    View
                                </Button>
                                <Button size="small" color="secondary">
                                    Edit
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            
            <Grid
                container
                spacing={4}
                className={classNames(classes.whitespace, classes.gutter)}
            >
                <Grid item sm={12} md={12} lg={12}>

                    <Grid
                        container
                        spacing={4}
                        className={classes.layout}
                    >
                        <Grid item sm={3} md={3} lg={3}>

                            <div>
                                <strong>List of Links</strong>
                                <br/>
                                Link one
                                <br/>
                                Link one
                                <br/>
                                Link one
                                <br/>
                                Link one
                                <br/>
                                Link one
                            </div>
                        </Grid>

                        <Grid item sm={9} md={9} lg={9}>
                            <TextField className={classes.search} label="Search" variant="outlined" fullWidth={true} />
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                            <p>This is a test</p>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
      
        </React.Fragment>
    );
}
