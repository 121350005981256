
import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import StaticPlaceholder from "../img/placeholder/static.gif";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Layout } from "../components/Layout";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {useAppContext} from "../context/Provider";
import {BLOB_STORAGE_URI} from "../constants/Constants";

const useStyles = makeStyles((theme) => createStyles({
    buttonStyle: {
        backgroundColor: theme.extendedProperties.accents.primary,
        color: theme.extendedProperties.foreground.primary,
    },
    contentContainer: {
        backgroundColor: theme.extendedProperties.background.primary,
        padding: 25,
        marginTop: 25,
        marginBottom: 25,
        minHeight: "82vh",
        color: theme.palette.primary.textColor
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.extendedProperties.foreground.primary,
        marginBottom: 10,
    },
    question: {
        backgroundImage: `url(${BLOB_STORAGE_URI}images/ask/ask.png)`,
        backgroundSize: 'stretch',
        backgroundPosition: 'bottom left',
        backgroundRepeat: 'no-repeat',
        height: 300,
        padding: '50px 0px 50px 0px',
        flexShrink: 0,
        alignSelf: 'center',
    },
    suggestionContent: {
        padding: '16px 16px 16px 16px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    
    suggestionTitle: {
        fontSize: 18,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    suggestionDescription: {
        fontSize: 16,
        color: '#a1a1a1',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3)
        },
        textAlign: "center",
        color: theme.palette.primary.textColor,
        backgroundColor: theme.extendedProperties.background.primary, //theme.palette.text.secondary
    },
    stepper: {
        padding: `${theme.spacing(1) * 3}px 0 ${theme.spacing(1) * 5}px`
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    icon: {
        marginRight: theme.spacing(2)
    },
    heroUnit: {
        backgroundColor: theme.palette.background.paper
    },
    heroContent: {
        maxWidth: 600,
        margin: "0 auto",
        padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`
    },
    heroButtons: {
        marginTop: theme.spacing(4)
    },

    layout: {
        width: "auto",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            width: 1100,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },

    whitespace: {
        width: 'auto',
        background: '#ffffff',
        padding: '0 0 100 0',
    },
    cardGrid: {
        padding: `${theme.spacing(0)}px 0`
    },
    cardMediaz: {
        padding: 0,
        margin: 0,
        height: 250,
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'tile',
        imageRendering: 'pixelated',
    },
    cardContent: {
        flexGrow: 1,
        typography: {
            color: theme.palette.primary.textColor
        },
        backgroundMedia: `url('${StaticPlaceholder}')`,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6)
    },

    repeatBg: {
        height: 250,
        backgroundRepeat: 'repeat',
        backgroundSize: 'auto',
    },
    slideContainer: {
        height: 200,
        width: 400
    },
    paddedTop: {
        alignContent: 'center',
        paddingTop: theme.spacing(2),
    },
    spaceBanner: {
        background: 'url(../images/stars.png) repeat',
        backgroundAttachment: 'fixed',
        width: '100%',
        height: '400px',
        animation: 'animatedBackground linear 45s infinite',
        overflow: 'hidden',
    },
    search: {
        width: '100%',
    },
    gutter: {
        marginTop: 20,
        paddingTop: 50,
    }
}));

const Submit = (props) => {

    const [state, dispatch] = useAppContext();
};

const RequestForm = (props) => {

    const [state, dispatch] = useAppContext();
    
    if (state.authenticated){
        return (
            <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Describe Your Request</DialogTitle>
                <DialogContent>
                    
                    <DialogContentText>
                        There's no guarantee it'll get done, but asking is the first step. If there's something you'd like 
                        to see created, ask away. If it's popular enough, it might just get done! 
                    </DialogContentText>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Give us a one liner version of your request."
                        fullWidth
                        variant="outlined"
                        style={{marginBottom: 15}}
                    />

                    <TextField
                        id="requestText"
                        label="Describe your request with a bit more detail."
                        multiline
                        rows={4}
                        defaultValue=""
                        fullWidth
                        variant="outlined"
                    />
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={props.handleClose} color="primary">
                        Subscribe
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
    else {
        return (
            <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Register</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You need to be registered to submit a suggestion. Would you like to register? It will require a Microsoft account.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={props.handleClose} color="primary">
                        Subscribe
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
};

export default function Community() {

    const classes = useStyles();
    const [suggestFormOpen, setSuggestFormOpen] = useState(false);

    const handleSuggestFormOpen = () => {
        console.log("HELLO THERE FORM OPEN");
        setSuggestFormOpen(true);
    };

    const handleSuggestFormClose = () => {
        setSuggestFormOpen(false);
    };

    return (
        <React.Fragment>
            
            <RequestForm open={suggestFormOpen} handleClose={handleSuggestFormClose}/>

            <Grid
                container
                spacing={4}
                style={{marginTop: 0, padding: '0px 50px 0px 50px', background: 'radial-gradient(ellipse at bottom, #19163e 0%, #000000 100%)', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}
            >
                <Grid item key="1" sm={12} md={12} lg={12} style={{paddingTop: 0}}>
                    
                    <div className={classes.question}>

                        <Typography gutterBottom variant="h3" component="h2" style={{color: '#ffffff', textShadow: '1px 1px 2px #000000'}}>
                            Have a Question<br/>or a Request?
                        </Typography>
                        
                        <Button variant="contained" className={classes.buttonStyle} style={{marginRight: 15}} onClick={handleSuggestFormOpen}>
                            Submit a Suggestion or Request
                        </Button>
                        
                    </div>
                    
                </Grid>
                
            </Grid>
            
            <Layout>
                <Grid
                    container
                    spacing={4}
                    style={{minHeight: '40vh', marginTop: 0, padding: '0px 50px 0px 50px'}}
                >
                    <Grid item key="1" sm={12} md={12} lg={12}>
                        
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Vote for Your Favorite Suggestion / Request
                                </Typography>
                                <Typography>
                                    Is there something that you've especially struggled to understand? Maybe there's a script 
                                    or report you wish existed that would help your life out a lot, but you don't know how to
                                    go about building it. Here's your opportunity to knock that out! Convince us it's a worthy 
                                    issue and we'll try to solve it for you.
                                </Typography>
                            </CardContent>
                        </Card>
                        
                    </Grid>
                    
                </Grid>

                <Grid
                    container
                    spacing={2}
                    style={{padding: '0px 50px 0px 50px'}}
                >
                    <Grid item key="1" sm={12} md={12} lg={12}>

                        
                        
                        <div className={classes.card}>

                            <div style={{backgroundColor: '#f5f5f5', border: 'solid 1px #d9d9d9', marginLeft: 10, width: 83, flexShrink: 0, alignSelf: 'center', justifySelf: 'center', textAlign: 'center',}}>

                                <div style={{width: '100%', backgroundColor: '#d9d9d9', color: '#ffffff', fontSize: 14, padding: '2px 3px 2px 3px'}}>⯅</div>
                                <div style={{fontSize: 24,}}>10</div>
                                
                            </div>
                            
                            <div className={classes.suggestionContent}>
                                <Typography className={classes.suggestionTitle}>
                                    This is a media card. You can use this section to describe
                                    the content.
                                </Typography>

                                <Typography className={classes.suggestionDescription}>
                                    This is a detail card. You can use this section to describe
                                    the content.
                                </Typography>
                            </div>
                        </div>

                        <div className={classes.card}>
                            <div className={classes.suggestionContent}>
                                <Typography className={classes.suggestionTitle}>
                                    This is a media card. You can use this section to describe
                                    the content.
                                </Typography>

                                <Typography className={classes.suggestionDescription}>
                                    This is a detail card. You can use this section to describe
                                    the content.
                                </Typography>
                            </div>
                        </div>



                    </Grid>
                </Grid>
                
            </Layout>

            



            

        </React.Fragment>
    );
}
