import React, {useEffect, useState,} from 'react';
import Grid from "@material-ui/core/Grid";
import "./../components/HeaderBanner";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {useNavigate, useParams} from "react-router";
import ContentLoader from "react-content-loader";
import PuffLoader from "react-spinners/PuffLoader";
import {css} from "@emotion/core";

const useStyles = makeStyles((theme) => createStyles({
    searchItem: {
        display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'left', alignContent: 'left', alignItems: 'flex-start',
    },
    searchHeadline: {
        margin: '0px 0px 0px 0px',
        color: '#7f4cc4',
        textDecoration: 'none',
        fontSize: 16,
    },
    searchSummary: {
        margin: '0px 0px 40px 0px',
    },
    loader: {
        position: 'fixed',
        zIndex: 9999999999,
        overflow: 'hidden',
        top: '20%',
        left: '50%',
        display: 'block',
        margin: '0 auto',
        borderColor: '#0fb28e',
    },
    innerContainer: {
        minHeight: 800,
    }
}));

const overlayLoader = css`
  position: fixed;
  top: 20%;
  left: 50%;
  display: block;
  margin: 0 auto;
  border-color: #0fb28e;
`;

export default function About() {

    const classes = useStyles();
    const { q } = useParams();
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    
    const navigate = useNavigate();

    useEffect(() => {
        async function getSearchResults() {
            try {
                setSearchLoading(true);
                const response = await fetch(
                    `/api/search/find?q=${q}`
                );
                
                const data = await response.json();
                setSearchResults(data.value);
            } catch (e) {
                console.error(e);
            }
            finally{
                setSearchLoading(false);
            }
        }
        getSearchResults().then(promise => {});
    }, []);

    const LoadingHelper = () => {
        
        if (searchLoading) {
            return (
                <React.Fragment>
                    <PuffLoader
                        css={overlayLoader}
                        size={200}
                        color={"#0fb28e"}
                        loading={searchLoading}
                    />
                    <ContentLoader
                        speed={2}
                        height={600}
                        viewBox="0 0 600 600"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        style={{width: '100%'}}
                    >
                        <rect x="9" y="7" rx="3" ry="3" width="281" height="15" />
                        <rect x="9" y="31" rx="3" ry="3" width="410" height="6" />
                        <rect x="9" y="47" rx="3" ry="3" width="399" height="6" />
                        <rect x="9" y="63" rx="3" ry="3" width="178" height="6" />
                        <rect x="9" y="107" rx="3" ry="3" width="281" height="15" />
                        <rect x="9" y="131" rx="3" ry="3" width="410" height="6" />
                        <rect x="9" y="147" rx="3" ry="3" width="399" height="6" />
                        <rect x="9" y="163" rx="3" ry="3" width="178" height="6" />
                        <rect x="9" y="207" rx="3" ry="3" width="281" height="15" />
                        <rect x="9" y="231" rx="3" ry="3" width="410" height="6" />
                        <rect x="9" y="247" rx="3" ry="3" width="399" height="6" />
                        <rect x="9" y="263" rx="3" ry="3" width="178" height="6" />
                        <rect x="9" y="307" rx="3" ry="3" width="281" height="15" />
                        <rect x="9" y="331" rx="3" ry="3" width="410" height="6" />
                        <rect x="9" y="347" rx="3" ry="3" width="399" height="6" />
                        <rect x="9" y="363" rx="3" ry="3" width="178" height="6" />
    
                        <rect x="9" y="407" rx="3" ry="3" width="281" height="15" />
                        <rect x="9" y="431" rx="3" ry="3" width="410" height="6" />
                        <rect x="9" y="447" rx="3" ry="3" width="399" height="6" />
                        <rect x="9" y="463" rx="3" ry="3" width="178" height="6" />
    
                        <rect x="9" y="507" rx="3" ry="3" width="281" height="15" />
                        <rect x="9" y="531" rx="3" ry="3" width="410" height="6" />
                        <rect x="9" y="547" rx="3" ry="3" width="399" height="6" />
                        <rect x="9" y="563" rx="3" ry="3" width="178" height="6" />
                    </ContentLoader>
                </React.Fragment>
            )
        }
        else {
            return (null)
        }
    }
    
    const SearchResults = () => {
        if (searchLoading){
            return <LoadingHelper/>
        }
        else if (searchResults.length === 0) {
            return (
                <React.Fragment>
                    <strong>Sorry, no results.</strong>
                    <p>Darn, it looks like your search didn't match up with anything we have in our data banks. Sorry!</p>
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                {searchResults.map((result, index) => {

                        console.log(result.document);

                        return (
                            <div key={index}>
                                <a href={result.document.ItemUri} className={classes.searchHeadline}><h2 style={{margin: 0}}>{result.document.Title}</h2></a>
                                <p className={classes.searchSummary}>{result.document.ItemTextConcat}
                                    <br/>
                                    <a href={result.document.ItemUri}>{result.document.ItemUri}</a>
                                </p>
                            </div>)}
                    )}
                </React.Fragment>
            )
        }
    }

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.item}>
                    <Grid container justify="center">
                        <Grid item className={classes.innerContainer} xs={9}>
                            <h1>Search</h1>
                            <div style={{minHeight: 400, marginBottom: 50}}>
                                <SearchResults/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>


        </React.Fragment>
    );
}
