import { Layout } from './components/Layout';
import React, {useEffect} from "react";
import { BrowserRouter, Routes, Router } from 'react-router-dom';
import { ApplicationAppBar } from "./components/ApplicationAppBar";
import { Footer } from "./components/Footer";
import { DIRECTORY_MAIN } from './data/directory'
import { createLocalTheme } from './jss/theme';
import CssBaseline from "@material-ui/core/CssBaseline";
import { AppProvider, useAppContext } from "./context/Provider";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BodySwitch } from "./components/BodySwitch";
import { AuthenticationProvider } from "./auth/AuthenticationProvider";
import * as ReactGA from 'react-ga';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

//const theme = createLocalTheme('dark');
//const theme = createMuiTheme(lightTheme);

const theme = createLocalTheme('light');

export const App = () => {

    useEffect(() => {
        ReactGA.initialize('UA-176841674-1', { debug: true });
        ReactGA.pageview(window.location.pathname + window.location.search);
        //ReactGA.pageview(window.location.pathname + window.location.search);
        
    }, []);
    
    return (
        <React.Fragment>
            <CssBaseline />
            <BrowserRouter basename={baseUrl}>
                <AppProvider>
                    <AuthenticationProvider>
                        <MuiThemeProvider theme={theme}>
                            <ApplicationAppBar/>
                            <BodySwitch/>
                            <Footer />
                        </MuiThemeProvider>
                    </AuthenticationProvider>
                </AppProvider>
            </BrowserRouter>
        </React.Fragment>
    );
};

export default App;