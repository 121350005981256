import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stars from './../img/banner/stars.png';
import { css } from "linaria";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import { SearchIcon } from '../icons/Icons';
import {useNavigate} from "react-router";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/styles/createStyles";

const animatedBackground = css`
    @keyframes starMovement {
        0% { background-position: 0px 0; }
        100% { background-position: 0px 509px; }
    }
    animation: starMovement 10s linear infinite
`;

const useStyles = makeStyles((theme) => createStyles({
    appBar: {
        position: 'relative',
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroUnit: {
        backgroundColor: '#aedcd2',//theme.palette.background.paper,
    },
    heroContent: {
        maxWidth: 600,
        margin: '0 auto',
        padding: `${theme.spacing(4)}px 0 ${theme.spacing(6)}px`,
        width: '100%',
        height: '400px',
        overflow: 'hidden',
        color: '#ffffff'
    },
    heroBackground: {
        background: 'radial-gradient(ellipse at bottom, #19163e 0%, #000000 100%)',
        width: '100%',
        height: '400px',
        padding: '0px',
        zIndex: 5,
        position: 'relative',
    },
    animatedBackground: {
        margin: '0 auto',
        padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
        background: 'url(' + Stars + ')',
        backgroundRepeat: 'repeat',
        width: '100%',
        height: '400px',
        overflow: 'hidden',
        position: 'absolute',
    },
    heroButton: {
        marginTop: theme.spacing(4),
        textShadow: '1px 1px 1px rgba(0,0,0,0.1)',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    cardGrid: {
        padding: `${theme.spacing(8)}px 0`,
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        padding: theme.spacing(6),
    },
    link: {
        color: "#c3e475",
    },
    overrides: {
        MuiFilledInput: {padding: 0},
        Link: {
            text: {
                color: "#c3e475",
            },
            link: {
                color: "#c3e475",
            },
            
        },
    },
    search: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontWeight: 500,
        background: '#ffffff',
        borderRadius: 5,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        borderColor: 'green !important',
        '&:focus': {
            boxShadow: `${(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: '#9c27b0',
        },
        
        '& label.Mui-focused': {
            color: '#9c27b0',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#9c27b0',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#9c27b0',
            },
            '&:hover fieldset': {
                borderColor: '#9c27b0',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#9c27b0',
            },
        },
    },
    input: {
        color: '#000000',
        padding: '0 5 0 5',
    },

    banana: {
        color: '#000000',
        padding: '0 5 0 5',
    }
}));

export const HeaderBanner = (props) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const [search, setSearch] = useState(''); 
    
    function handleSearch (e) {
        setSearch(e.target.value);
    }
    
    function executeSearch() {
        navigate(`/search/${search}`);
    }
    



    return (
        <React.Fragment>
            <div className={classes.heroUnit}>
                <div className={classes.heroBackground}>
                    <div className={classes.animatedBackground + ' ' + animatedBackground}>
                        <div className={classes.heroContent}>
                            <Typography component="h1" variant="h2" align="center" gutterBottom>
                                Winning with Data!
                            </Typography>
                            <Grid container spacing={2} justify="center" >
                                <Grid item sm={12} md={12} lg={12} >
                                    <form noValidate autoComplete="off">
                                        <TextField 
                                            className={classes.search} 
                                            autoFocus={true}
                                            variant="filled"
                                            onChange={handleSearch}
                                            value={search}
                                            onKeyPress={(ev) => {
                                                if (ev.key === 'Enter') {
                                                    ev.preventDefault();
                                                    executeSearch();
                                                }
                                            }}
                                            classes={{
                                                root: classes.root, // class name, e.g. `classes-nesting-root-x`
                                                input: classes.banana, // class name, e.g. `classes-nesting-label-x`
                                                MuiFilledInput: { style: {padding: 0}},
                                            }}
                                            classes={{
                                                root: classes.root, // class name, e.g. `classes-nesting-root-x`
                                                label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                            }}
                                            InputProps={{
                                                startAdornment: <InputAdornment style={{margin: 0}}  position="start"><SearchIcon size={24}/></InputAdornment>,
                                                inputProps: {style: {padding: '15px 10px 15px 10px'}},
                                            }}                                        
                                        />
                                    </form>
                                </Grid>
                            </Grid>
                            <Typography variant="h6" align="center" paragraph>
                                Just a few notes on Power BI Report Server, SSRS, SSAS, SSIS, and SQL Server
                            </Typography>
                            <Grid container spacing={2} justify="center">
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
