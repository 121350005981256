import {DIRECTORY_MAIN} from "../data/directory";
import {Route, Routes} from "react-router-dom";
import React, {useEffect} from "react";
import {useAppContext} from "../context/Provider";

export const BodySwitch = () => {

    const [state, dispatch] = useAppContext();
    
    useEffect(() => {
        const pageTitle = state.title ?? "Title";
        document.title = pageTitle;
    }, [state.title]);
    
    return (
        <Routes>
            {
                DIRECTORY_MAIN.map((dir, index) => {
                    
                    const isExact = (dir.slug === '').toString();
                    
                    if (typeof(dir.allowedActions) == 'undefined') {
                        return (
                            <Route
                                key={index}
                                exact={isExact}
                                path={dir.slug + "/*"}
                                element={dir.component}
                            />
                        )
                    }
                    else if (state.authenticated && state.allowedActions.includes(dir.requiredAction)) {
                        return (
                            <Route
                                key={index}
                                exact={isExact}
                                path={dir.slug + "/*"}
                                element={dir.component}
                            />
                        )}
                    else if (dir.allowedActions == null) {
                        return (
                            <Route
                                key={index}
                                exact={isExact}
                                path={dir.slug + "/*"}
                                element={dir.component}
                            />
                        )}
                    
                })
            }
        </Routes>
    )
};
