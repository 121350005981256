import React from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {AuthorLink} from "./AuthorLink";

const useStyles = makeStyles((theme) => createStyles({

    detailBoxAvatar: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: 50,
        backgroundColor: '#cccccc',
    },

    detailBoxDate: {
        fontSize: 16,
        color: theme.extendedProperties.grayscale.primary,
    },

    detailBoxAuthor: {
        fontSize: 20,
        textDecoration: 'none',
        color: theme.extendedProperties.grayscale.primary,
    },

    detailBoxDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: 400,
        paddingLeft: 10,
        justifyContent: 'center',
        alignItems: 'bottom',
    },

    detailBoxHorizontalDetails: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 10,
        justifyContent: 'center',
        alignItems: 'center',
        
    },

    authorLink: {
        textDecoration: 'none',
        color: theme.palette.primary.linkColor,
        fontWeight: 400,
        fontSize: 24,
        margin: 0,
    },

}));

export const Avatar = (props) => {
    
    const classes = useStyles();
    var size = props.size || 80;
    
    return (
        <div className={classes.detailBoxAvatar} style={{height: size, width: size, backgroundImage: `url(https://www.gravatar.com/avatar/${props.hash}?d=identicon&size=${size})`}}>
        </div>
    )
};

export const AvatarDetails = (props) => {

    const classes = useStyles();
    var size = props.size || 64;
    
    return (
        <div className={classes.detailBoxDetails} style={{height: size}}>

            <div className={classes.detailBoxAuthor}>
                <AuthorLink authorId={props.authorId} authorName={props.author ?? ''} style={{}}/>
            </div>

            <div className={classes.detailBoxDate}>
                <Moment format="MMMM  Do, YYYY">{props.createdDtm}</Moment>
            </div>

        </div>
    )
};

export const AvatarHorizontalDetails = (props) => {

    const classes = useStyles();
    var size = props.size || 64;

    return (
        <div className={classes.detailBoxHorizontalDetails} style={{height: size}}>

            <div className={classes.detailBoxAuthor}>
                <AuthorLink authorId={props.authorId} authorName={props.author ?? ''} style={classes.authorLink}/>
            </div>

            <div style={{margin: '0px 15px 0px 15px', color: '#e1e1e1'}}>
                —
            </div>
            
            <div className={classes.detailBoxDate} style={{fontSize: 20, fontWeight: 200}}>
                <Moment format="MMMM  Do, YYYY">{props.createdDtm}</Moment>
            </div>

        </div>
    )
};