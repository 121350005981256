import React, { createContext, useReducer, useContext } from "react";
import { editorReducer } from "./EditorReducers";
import { editorInitialState, EditorState } from "./EditorState";
type Dispatch = (action: Action) => void
type EditorProviderProps = {children: React.ReactNode}
//import { EditorAction } from "./EditorActions";

const EditorStateContext = React.createContext();
const EditorDispatchContext = React.createContext();

// PROVIDER
function EditorProvider({children}) {
    const [state, dispatch] = useReducer(editorReducer, editorInitialState);
    return (
        <EditorStateContext.Provider value={state}>
            <EditorDispatchContext.Provider value={dispatch}>
                {children}
            </EditorDispatchContext.Provider>
        </EditorStateContext.Provider>
    )
}

function useEditorState() {
    const context = useContext(EditorStateContext);
    if (context === undefined) {
        throw new Error('useEditortate must be used within a EditorProvider')
    }
    return context
}

function useEditorDispatch() {
    const context = useContext(EditorDispatchContext);
    if (context === undefined) {
        throw new Error('useEditorDispatch must be used within a EditorProvider')
    }
    return context
}

function useEditorContext() {
    return [useEditorState(EditorStateContext), useEditorDispatch(EditorDispatchContext)]
}

export {EditorProvider, useEditorState, useEditorDispatch, useEditorContext}