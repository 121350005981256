import {useAppContext} from "../../context/Provider";
import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {useNavigate} from "react-router";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {createStyles, ListItemIcon, ListItemText} from "@material-ui/core";
import {EditIcon} from "../../icons/Icons";
import Moment from "react-moment";
import {DeleteOutline as DeleteIcon, Publish as PublishIcon} from "@material-ui/icons";
import Dropzone from "react-dropzone-uploader";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import cogoToast from "cogo-toast";

const useStyles = makeStyles((theme) => createStyles({

    table: {

    },
    dropzone: {
        backgroundColor: '#efefef',
        border: 'dashed 2px #cccccc',
        width: '100%',
        color: '#cccccc',
        fontWeight: 800,
        padding: 0,
        margin: '5 0 10 0',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 120,
        position: 'relative',
        boxSizing: 'border-box',
        /*
        -webkit-transition: all .15s linear;
        -o-transition: all .15s linear;*/
        transition: 'all .15s linear',
    },
    dropzoneActive: {
    },
    dropzoneReject: {
    },
    dropzoneDisabled: {
    },

}));

export const AdministrationDrafts = (props) => {
    const classes = useStyles();
    const [state, dispatch] = useAppContext();
    const [openPublishDialog, setOpenPublishDialog] = useState(false);
    const [publishItemId, setPublishItemId] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(false);

    function publishItemTrigger(itemId) {
        setPublishItemId(itemId);
        setOpenPublishDialog(true);
    }
    function deleteItemTrigger(itemId) {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    }

    function deleteItemFinalize() {
        console.log('publish: ' + publishItemId);
    }

    const PublishDialog = () => {

        function publishItemFinalize() {
            let apiUri = `/api/content/publish?published=true&itemId=${publishItemId}`;
            let options= {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                //body: JSON.stringify(data),
                referrer: 'no-referrer',
            };

            fetch(apiUri, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json; charset=utf-8',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                //body: JSON.stringify(data),
            }).then(
                data => {
                    cogoToast.success('Publish Complete!')
                }
            );

            setOpenPublishDialog(false);
        }

        return (
            <Dialog open={openPublishDialog} onClose={() => setOpenPublishDialog(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Publish Content</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please confirm you would like to submit this content to be published.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPublishDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => publishItemFinalize()} color="primary">
                        Publish
                    </Button>
                </DialogActions>
            </Dialog>
        )
    };

    function GetDrafts() {
        let apiUri = "/api/content/get-drafts?published=0";
        let options= {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            //body: JSON.stringify(data),
            referrer: 'no-referrer',
        };
        useEffect(() => {
            async function GetDrafts() {
                try {
                    dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: true });
                    const response = await fetch(apiUri, options);
                    const data = await response.json();
                    setTableData(data);
                } catch (e) {
                    console.error(e);
                }
                finally {
                    dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: false });
                }
            }
            GetDrafts().then(promise => {
            });
        }, []);
    }

    GetDrafts();
    
    const [tableData, setTableData] = React.useState([{}]);
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <PublishDialog/>
            <h1>Drafts</h1>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Item</TableCell>
                        <TableCell align="right">Modified</TableCell>
                        <TableCell align="right">Created</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell align="left">
                                <ListItem button component="a" onClick={() => navigate(`/administration/edit/${row.itemId}`)}>
                                    <ListItemIcon><EditIcon/></ListItemIcon>
                                    <ListItemText primary={row.title} />
                                </ListItem>
                            </TableCell>
                            <TableCell align="right"><Moment format="MM/DD/YYYY HH:mm">{row.modifiedDtm}</Moment></TableCell>
                            <TableCell align="right"><Moment format="MM/DD/YYYY HH:mm">{row.createdDtm}</Moment></TableCell>
                            <TableCell align="center">
                                <Button onClick={() => publishItemTrigger(row.itemId)}>
                                    <PublishIcon/>
                                </Button>
                                <Button onClick={() => deleteItemTrigger(row.itemId)}>
                                    <DeleteIcon/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </React.Fragment>
    )
};

export const UploadImageDialog = (props) => {

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Upload Media</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    All items uploaded to the media library will be accessible to all editors and can be used in any
                    content on the site. Deleting this media can break content using it.
                </DialogContentText>
            </DialogContent>
            <div style={{padding: 20}}>
                <Uploader/>
            </div>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
};

const Uploader = () => {

    const classes = useStyles();
    const styles = {
        dropzone: classes.dropzone,
        //dropzoneActive: classes.dropzoneActive,
        //dropzoneReject: classes.dropzoneReject,
        //dropzoneDisabled: classes.dropzoneDisabled,
        //input: classes.input,
        inputLabel: classes.inputLabel,
        //inputLabelWithFiles: classes.inputLabelWithFiles,
        //preview: classes.preview,
        //previewImage: classes.previewImage,
        //submitButtonContainer: classes.submitButtonContainer,
        //submitButton: classes.submitButton
    };
    
    const getUploadParams = ({ file, meta }) => {
        const url = '/api/files/upload';
        //const body = new FormData();
        const fields = {
            fileName: meta.name
        };
        return { url, fields };
    };

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) };

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta));
        allFiles.forEach(f => f.remove());
    };

    return (
        <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            accept="*"
            classNames={styles}
        />
    )
};