import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormLogin from './FormLogin';
import FormSignUp from './FormSignUp';
import Box from '@material-ui/core/Box';
import insert_coin from './../../img/auth/insert-coin.gif';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {useAppContext} from "../../context/Provider";


const useStyles = makeStyles((theme) => createStyles({
    formDialogTitle: {
        color: 'primary',
    },

    mainContainer: {
        transition: 'all 1s ease !important',
        overflow: 'hidden',
    },
    primaryBox: {
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    secondaryBox: {
        padding: 40,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#000000',
        color: '#ffffff',
        textAlign: 'center',
    },
    insertCoin: {
        width: 271,
        height: 15,
        margin: '0 auto 0 auto',
    },
    tabClass: {
        borderBottom: 'solid 1px #cccccc', 
        marginBottom: 16,
    },
}));

export default function AuthenticationModal() {
    
    const classes = useStyles();
    const [state, dispatch] = useAppContext();
    
    return(
        <Dialog
            open={state.authModalOpen}
            maxWidth="md"
            fullWidth
        >
            <Grid container className={classes.mainContainer}>
                <Grid item sm={12} md={6} className={classes.primaryBox}>
                    
                    <Typography component="h1" variant="h2" style={{textAlign: 'center', width: '100%',}} color="textPrimary" gutterBottom>
                        GG
                    </Typography>
                 
                    <Tabs
                        value={state.authModalTab}
                        onChange={(event, value) => { dispatch({ type: 'SET_AUTH_MODAL_TAB', value })}}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        className={classes.tabClass}
                    >
                        <Tab label="Login" value="Login" />
                        <Tab label="Sign Up" value="SignUp" />
                    </Tabs>


                    {state.authModalTab === "Login" && <FormLogin/>}
                    {state.authModalTab === "SignUp" && <FormSignUp/>}
                    
                </Grid>
                
                <Box display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex',}} clone>
                    <Grid item xs={12} sm={6} className={classes.secondaryBox}>
                        <Typography component="h1" variant="h2" align="center" gutterBottom>
                            GG THIS IS MOBILE HIDDEN
                        </Typography>
                        <img className={classes.insertCoin} src={insert_coin} alt="Insert Coin"/>
                    </Grid>
                </Box>
            </Grid>
        </Dialog>
    );
}
