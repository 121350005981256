import Prism from 'prismjs';
import createPrismPlugin from 'draft-js-prism-plugin';
import { composeDecorators } from 'draft-js-plugins-editor';
import 'prismjs/components/prism-powershell';
import 'prismjs/components/prism-javascript';
import "prismjs/components/prism-sql";
import "prismjs/components/prism-c";
import './../editor/theme/prism-material-dark.css';
import React, {useEffect, useState} from "react";
import { DefaultVideoComponent}  from "./plugins/video/DefaultVideoComponent";
import createImagePlugin from "draft-js-image-plugin";
import createToolbarPlugin from "draft-js-static-toolbar-plugin";
import createLinkPlugin from 'draft-js-anchor-plugin';
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import createEmojiPlugin from 'draft-js-emoji-plugin';
import linkStyles from './theme/linkStyles.css';
import createInlineToolbarPlugin from "./plugins/custom-inline-toolbar-plugin/src";
import createVideoPlugin from "draft-js-video-plugin";
import { css } from 'linaria';

import { Entity } from 'draft-js';
import utils from 'draft-js-video-plugin/lib/video/utils';
import addVideo from 'draft-js-video-plugin/lib/video/modifiers/addVideo';
import * as customType from 'draft-js-video-plugin/lib/video/constants';

import { useEditorContext } from "./EditorProvider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/styles/createStyles";
import {BLOB_STORAGE_URI} from "../constants/Constants";

const linkPlugin = createLinkPlugin({
    theme: linkStyles,
    placeholder: 'http://…'
});

export const imagePlugin = createImagePlugin({
    theme: {
        image: 'editorImage'
    }
});

const toolbarPlugin = createToolbarPlugin();

const prismPlugin = createPrismPlugin({
    // It's required to provide your own instance of Prism
    prism: Prism
});

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const emojiPlugin = createEmojiPlugin();

export const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
export const { AlignmentTool } = alignmentPlugin;
export const { Toolbar } = toolbarPlugin;
export const { LinkButton } = linkPlugin;

export const inlineToolbarPlugin = createInlineToolbarPlugin();

//export const { InlineToolbar } = inlineToolbarPlugin;

export const decorators = composeDecorators(
    resizeablePlugin.decorator,
    focusPlugin.decorator,
    blockDndPlugin.decorator,
);

const videoPluginTheme = {
    iframeContainer: css`
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
  `,

        iframe: css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  `,

        invalidVideoSrc: css`
    text-align: center;
    background-color: #eaeaea;
    padding: 1em;
  `,

        video: css`
    width: 100%;
    height: 100%;
  `,
};

// source to use as guide: https://github.com/draft-js-plugins/draft-js-plugins/tree/master/draft-js-video-plugin/src
export const videoPlugin = createVideoPlugin({
    theme: videoPluginTheme,
});

export const plugins = [
    prismPlugin,
    emojiPlugin,
    focusPlugin,
    alignmentPlugin,
    inlineToolbarPlugin,
    toolbarPlugin,
    resizeablePlugin,
    linkPlugin,
    videoPlugin,
    imagePlugin,
];
