import React from "react";

export const VisibilityToggle = (props) => {

    const {visibility} = props;    

    if (visibility) {
        return (
            <React.Fragment>
                {props.children}
            </React.Fragment>
        )
    }
    return null
    
};
