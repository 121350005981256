
import React, {useEffect, useRef, useState} from "react";
import Grid from "@material-ui/core/Grid";
import StaticPlaceholder from "../img/placeholder/static.gif";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {Route, Routes, useNavigate, useParams, Link} from "react-router-dom";
import {convertFromRaw, EditorState, getDefaultKeyBinding, Modifier, RichUtils} from "draft-js";
import Editor from 'draft-js-plugins-editor';
import { Layout } from "../components/Layout";
import decorators from "../editor/decorator";
import {Avatar, AvatarDetails, AvatarHorizontalDetails} from "../components/Avatar";
import { MdPermDataSetting} from "react-icons/md";
import { DiMsqlServer } from "react-icons/di";
import {AiOutlineFolder } from "react-icons/ai";
import { GrCube } from "react-icons/gr";
import { GoGraph } from "react-icons/go";
import { BsClipboardData } from "react-icons/bs";
import {BLOB_STORAGE_URI} from "../constants/Constants";
import {EditorProvider, useEditorContext} from "../editor/EditorProvider";
import {decorator, plugins} from "../editor/plugins";
import Prism from "prismjs";
import {useAppContext} from "../context/Provider";
import ContentLoader from "react-content-loader";
import {VisibilityToggle} from "../components/VisibilityToggle";
import {ColorLikeIcon, ColorInactiveLikeIcon, CommentIcon} from '../icons/Icons';
import Chip from "@material-ui/core/Chip";
import {AdaptiveLikeIcon} from "../components/AdaptiveLikeIcon";
import {LoginPrompt} from "../components/LoginPrompt";
import ReactUtterences from "react-utterances";
import {CategoryCounts} from "../components/CategoryCounts";
import {AuthorLink} from "../components/AuthorLink"
import {ContentList} from "../components/ContentList";

const useStyles = makeStyles((theme) => createStyles({
    categoryHeader: {
        width: '100%',
        height: 50,
        
    },
    iconContainer: {
        display: 'flex',
        backgroundColor: '#efefef', 
        color: 'black', 
        borderRadius: 50, 
        padding: 10, 
        width: 50, 
        height: 50,
        justifyContent: 'center',
    },
    icon: {
        alignSelf: 'center',
    },
    contentDetailBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        height: 44,
    },
    editButton: {
        height: 'auto',
    },
    authorLink: {  
        textDecoration: 'none',
        color: theme.palette.primary.linkColor,
        fontWeight: 800,
    },
    feedbackButton: {
        borderRadius: 50, 
        margin: '0px 5px 0px 5px', 
        padding: '10px 20px 10px 20px', 
        backgroundColor: '#efefef'
    }
}));

const cards = [
    {
        key: 1,
        title: 'SQL Server',
        icon: DiMsqlServer
    },
    {
        key: 2,
        title: 'SQL Server Reporting Services',
        icon: GoGraph,
    },
    {
        key: 3,
        title: 'SQL Server Integration Services',
        icon: GrCube,
    },
    {
        key: 4,
        title: 'SQL Server Analysis Services',
        icon: MdPermDataSetting,
    },
    {
        key: 5,
        title: 'Power BI',
        icon: BsClipboardData,
    },
    {
        key: 6,
        title: 'Other',
        icon: AiOutlineFolder
    },
    ];

const EditButton = () => {
    
    const classes = useStyles();
    const [state, dispatch] = useAppContext();
    const navigate = useNavigate();
    const { id } = useParams();

    if (typeof(state.allowedActions) !== 'undefined'
        && Array.isArray(state.allowedActions)
        && state.allowedActions.some(action => action.actionName === 'global_admin')) 
    {
        return (
            <Button className={classes.editButton} variant="contained" color="primary" onClick={() => navigate(`/administration/edit/${id}`)}>
                Edit
            </Button>
        )
    }
    else {
        return null;
    }
    
};

const FeedbackSection = (props) => {

    const classes = useStyles();
    const [userDetails, setUserDetails] = useState({});
    const [loadingDetail, setLoadingDetail] = useState(true);
    const [liked, setLiked] = useState(false);
    const [clickTimes, setClickTimes] = useState(0);
    const { id } = useParams();
    
    useEffect(() => {
        if (props.authorId > 0) {
                getContentFeedback().then(promise => {
            });
        }
    }, [props.authorId]);
    
    function increaseClickCount(){
        setClickTimes(clickTimes + 1);
    }
    
    async function getContentFeedback() {
        try {
            const response = await fetch(
                `/api/user/details?id=${props.authorId}`
            );
            //const data = await response.json();
            //setUserDetails(data);

        } catch (e) {
            console.error(e);
        } finally {
            setLoadingDetail(true);
        }
    }

    function commentClick() {

    }

    function likeClick() {
        if (clickTimes < 10) {
            increaseClickCount();

            let flag = !liked;
            
            try {
                const response = fetch(
                    `/api/content/like?itemId=${id}&like=${flag}`
                );
                const data = response.json();

                //dispatch({ type: 'SET_CATEGORIES', value: data });
            } catch (e) {
                console.error(e);
            }
      
            
            setLiked(!liked);
        }
    }

    return (
        <VisibilityToggle visibility={loadingDetail}>
            <div style={{width: '100%', borderTop: '1px solid #ccc', margin: '20px 0px 20px 0px'}}/>
            <div style={{display: 'flex', flexDirection: 'row',}}>
                <Button className={classes.feedbackButton} onClick={() => likeClick()}>
                    <AdaptiveLikeIcon liked={liked} size={24}/>
                    <div style={{margin: '0px 5px 0px 10px'}}>
                        Leave a like!
                    </div>
                </Button>

                <Button style={{borderRadius: 50, padding: '10px 20px 10px 20px', backgroundColor: '#efefef'}} onClick={() => commentClick()} >
                    <CommentIcon size={24}/>
                    <div style={{margin: '0px 5px 0px 10px'}}>
                        Leave a comment!
                    </div>
                </Button>
                
                <div style={{display: 'flex', flexDirection: 'column',}}>
                </div>
            </div>
        </VisibilityToggle>
    )
};

const AuthorSection = (props) => {

    const classes = useStyles();
    const [userDetails, setUserDetails] = useState({});
    const [loadingDetail, setLoadingDetail] = useState(true);

    useEffect(() => {
        if (props.authorId > 0) {
            getUserDetails().then(promise => {
            });
        }
    }, [props.authorId]);

    async function getUserDetails() {
        try {
            const response = await fetch(
                `/api/user/details?id=${props.authorId}`
            );
            const data = await response.json();
            setUserDetails(data);

        } catch (e) {
            console.error(e);
        } finally {
            setLoadingDetail(false);
        }
    }

    let avatarUri = `${BLOB_STORAGE_URI}/biwin/images/about/user.jpg`;

    if (userDetails.avatarSet) {
        avatarUri = `${BLOB_STORAGE_URI}users/${props.authorId}/avatar.jpg`;
    }
    
    let authorDisplayName = '';
    if (typeof(userDetails.displayName) !== 'undefined') {
        authorDisplayName = userDetails.displayName.toLowerCase().replace(' ', '');
    }

    return (
        <VisibilityToggle visibility={!loadingDetail}>
            <div style={{width: '100%', borderTop: '1px solid #ccc', margin: '40px 0px 40px 0px'}}/>
            <div style={{display: 'flex', flexDirection: 'row',}}>
                <div style={{marginRight: 20, flexShrink: 0, borderRadius:100, width: 140, height: 140, backgroundColor: '#cccccc', backgroundImage: `url(${avatarUri})`, backgroundSize: 'cover',}}>
                </div>
                <div style={{display: 'flex', flexDirection: 'column',}}>
                    <AuthorLink style={classes.authorLink} authorName={userDetails.displayName} authorId={props.authorId}/>
                    <span>{userDetails.bio}</span>
                </div>
            </div>
            <ReactUtterences repo={'okpedro/socialwintelligence'} type={'pathname'} />
        </VisibilityToggle>
    )
};

const ContentDefault = (props) => {
    
    const classes = useStyles();
    const [state, dispatch] = useAppContext();

    useEffect(() => {
        dispatch({type: 'SET_TITLE', value: "Business wIntelligence - Home of Microsoft BI Nerds"});
    }, []);
    
    return (
        <Layout
            suppressPadding={true}
            >
            <CategoryCounts/>
            <Grid
                container
                spacing={4}
                className={classNames(classes.whitespace, classes.gutter)}
            >
                <Grid item sm={12} md={12} lg={12}>
                    
                </Grid>
            </Grid>
        </Layout>
    )
};

const ContentItem = (props) => {

    const { id } = useParams();
    const classes = useStyles();
    const [state, dispatch] = useEditorContext();
    const [appState, appDispatch] = useAppContext();
    const [contentData, setContentData] = useState(EditorState.createEmpty());
    const [contentDetails, setContentDetails] = useState({
        title: 'Business Wintelligence Article',
        author: '',
        createdDtm: '',
        categoryId: 1,
        subCategoryId: 1,
        authorId: -1,
    });

    useEffect(() => {
        const newTitle = contentDetails.title + " - Business wIntelligence";
        appDispatch({type: 'SET_TITLE', value: newTitle ?? "Business wIntelligence"});
    },[contentDetails.title]);

    const ItemContentLoader = (props) => {

        if (state.contentLoading) {
            return (
                <ContentLoader
                    speed={2}
                    viewBox="0 0 400 400"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    style={{ width: '100%', marginTop: 20}}
                    {...props}
                >
                    <rect x="34" y="44" rx="3" ry="3" width="212" height="10"/>
                    <rect x="6" y="66" rx="3" ry="3" width="383" height="165"/>
                    <rect x="6" y="243" rx="3" ry="3" width="410" height="6"/>
                    <rect x="6" y="259" rx="3" ry="3" width="380" height="6"/>
                    <rect x="6" y="275" rx="3" ry="3" width="178" height="6"/>
                    <circle cx="20" cy="50" r="9"/>
                    <rect x="7" y="5" rx="3" ry="3" width="88" height="6"/>
                    <rect x="8" y="18" rx="3" ry="3" width="250" height="17"/>
                    <rect x="7" y="297" rx="3" ry="3" width="410" height="6"/>
                    <rect x="7" y="313" rx="3" ry="3" width="380" height="6"/>
                    <rect x="7" y="329" rx="3" ry="3" width="178" height="6"/>
                </ContentLoader>
            )
        } else {
            return null
        }
    };

    const editorRef = useRef();

    const getBlockStyle = (block) => {
        switch (block.getType()) {
            case 'code-block':
                return 'language-sql';
            default:
                return null;
        }
    };

    const keyBinding = (e) => {
        if (e.keyCode === 13 && e.shiftKey) return 'soft-break';
        const selection = contentData.getSelection();
        const block = contentData
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey());
        if (e.key === 'Tab' && block.getType() === 'code-block') return 'code-tab';
        return getDefaultKeyBinding(e);
    };

    const handleKeyCommand = (command, state) => {

        const newState = RichUtils.handleKeyCommand(state, command);

        if (command === 'code-block') {
            setContentData(RichUtils.toggleBlockType(state, 'code-block'));
            return 'handled';
        }
        else if (command === 'soft-break') {
            setContentData(RichUtils.insertSoftNewline(state));
            return 'handled';
        }
        else if (command === 'code-tab') {
            const currentState = state;
            const newBlockState = Modifier.replaceText(
                currentState.getCurrentContent(),
                currentState.getSelection(),
                '\t'
            );
            (
                setContentData(EditorState.push(currentState, newBlockState, 'insert-characters'))
            );
        }
        if (newState) {
            (
                setContentData(newState)
            );
            return 'handled';
        }
        return 'not-handled';
    };

    const toggleBlockType = (type) => {
        (
            setContentData(RichUtils.toggleBlockType(contentData, type))
        );
    };

    const handleChange = (newEditorState) => {
        const selection = newEditorState.getSelection();
        const block = newEditorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey());
        const currentContent = contentData.getCurrentContent();
        const newContent = newEditorState.getCurrentContent();
        if (
            block.getType() === 'code-block' &&
            !newContent.equals(currentContent)
        ) {
            const data = block.getData().merge({ language: 'sql' });
            const newBlock = block.merge({ data });
            const newContentState = newEditorState.getCurrentContent().merge({
                blockMap: newEditorState
                    .getCurrentContent()
                    .getBlockMap()
                    .set(selection.getStartKey(), newBlock),
                selectionAfter: selection
            });
            (
                setContentData(EditorState.push(newEditorState, newContentState, 'change-block-data'))
            );
        } else (
            setContentData(newEditorState)
        );
    };
    
    useEffect(() => {
        
        let authorId = -1;
        async function getSavedContent() {
            try {
                window.scrollTo(0, 0);
                dispatch({type: 'SET_CONTENT_LOADING', value: true});

                const response = await fetch(
                    `/api/content/retrieve?itemId=${id}`
                );

                const data = await response.json();
                if (data.itemContent) {
                    data.editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(data.itemContent)));
                    setContentData(data.editorState);
                    setContentDetails({
                        title: data.title,
                        authorId: data.authorId,
                        authorDisplayName: data.authorDisplayName,
                        createdDtm: data.createdDtm,
                        publishedDtm: data.publishedDtm,
                        category: data.category,
                        subCategory: data.subCategory,
                        authorHash: data.authorHash,
                        bannerUri: BLOB_STORAGE_URI + data.bannerUri,
                    });

                    authorId = data.authorId;
                }
            } 
            catch (e) {
                console.error(e);
            }
            finally{
                dispatch({type: 'SET_CONTENT_LOADING', value: false});
            }
        }

        getSavedContent().then(promise => {
        });
        
    }, []);
    
    const [loginPromptOpen, setLoginPromptOpen] = useState(false);
    
    function handleLoginPromptOpen(){
        
    }
    
    return (
        <React.Fragment>
            <Layout>
                <Grid container justify="center" style={{marginBottom: 120}}>
                    <Grid item className={classes.paperGrid} xs={10} sm={9} md={9}>
                        <ItemContentLoader/>
                        <LoginPrompt open={loginPromptOpen} handleOpen={handleLoginPromptOpen} />
                        
                        <VisibilityToggle visibility={!state.contentLoading}>
                            <h4 style={{marginBottom: 0}}>{contentDetails.category} > {contentDetails.subCategory}</h4>
                            <h1 style={{margin: '0px 0px 5px 0px'}}>{contentDetails.title}</h1>
                            
                            <div className={classes.contentDetailBox}>
                                <Avatar hash={contentDetails.authorHash} size={30}/>
                                <AvatarHorizontalDetails size={30} createdDtm={contentDetails.publishedDtm} author={contentDetails.authorDisplayName} authorId={contentDetails.authorId}/>
                                <div style={{flexGrow: 1}}>
                                </div>
                                <EditButton/>
                            </div>
                            
                            <div style={{width: '100%', marginBottom: 24, }}>
                                <img src={contentDetails.bannerUri} style={{display: 'block', width: '100%', height: 'auto', }}/>
                            </div>
                            
                            <Editor
                                className={classes.editor}
                                onChange={handleChange}
                                editorState={contentData}
                                plugins={plugins}
                                readOnly={true}
                                decorators={decorators}
                                blockStyleFn={getBlockStyle}
                            />
                        </VisibilityToggle>
                        <FeedbackSection/>
                        <AuthorSection authorId={contentDetails.authorId}/>
                    </Grid>
                </Grid>
                
            </Layout>
            
        </React.Fragment>
    )
};

const CategoryLister = (props) => {

    const { id } = useParams();

    return (
        <Layout
            suppressPadding={true}
        >
            <ContentList categoryId={id}/>
        </Layout>
    )
}

const SubcategoryLister = (props) => {

    const { id } = useParams();

    return (
        <Layout
            suppressPadding={true}
        >
            <ContentList subcategoryId={id}/>
        </Layout>
    )
}


export default function Content() {

    return (
        <Routes>
            
            <Route path={`/category/:id/:slug`} element={<CategoryLister/>}/>
            <Route path={`/category/:id`} element={<CategoryLister/>}/>
            <Route path={`/subcategory/:id/:slug`} element={<SubcategoryLister/>}/>
            <Route path={`/subcategory/:id`} element={<SubcategoryLister/>}/>
            
            <Route path={`:id`} element={<EditorProvider><ContentItem/></EditorProvider>}/>
            <Route path={`:id/:slug`} element={<EditorProvider><ContentItem/></EditorProvider>}/>
            <Route path={`/`} element={<ContentDefault/>}/>
        </Routes>
    );
}
