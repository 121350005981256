import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card/Card";
import {Link, Route, Routes, useNavigate, useParams} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions/CardActions";
import React, {useEffect, useState} from "react";
import {BLOB_STORAGE_URI} from "../constants/Constants";
import {useAppContext} from "../context/Provider";
import {OverlaySpinner} from "./spinners/OverlaySpinner";
import {UserDetail, UserUnknown} from "../views/User";
import createStyles from "@material-ui/styles/createStyles";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ContentLoader from "react-content-loader";
import Moment from "react-moment";

const useStyles = makeStyles((theme) => createStyles({

    container: {
        marginTop: 10,
        padding: '0px 50px 0px 50px',
        minHeight: 800,
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    userImage: {
        width: 200,
        height: 200,
        borderRadius: 100,
        backgroundColor: '#cccccc',
        backgroundImage: 'url(`${BLOB_STORAGE_URI}/biwin/images/about/user.jpg`)',
        backgroundSize: 'cover',
    },
    userBio: {
        flexGrow: 1,
        height: 200,
        marginLeft: 40,
        padding: 40,
    },
    cardGrid: {
        padding: `${theme.spacing(0)}px 0`
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.extendedProperties.foreground.primary
    },
    cardContent: {
        padding: '10px 0px 0px 0px',

    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6)
    },
    cardHeader: {
        color: '#cccccc',
        margin: 0,
        fontSize: 18,
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.linkColor,
        '&:hover': {
            color: theme.extendedProperties.accents.primary,
        },
    },
    darkLink: {
        textDecoration: 'none',
        color: theme.palette.primary.main,

    },
    authorName: {
        fontFamily: theme.typography.headerFontFamily,
        color: '#cdcdcd',
        fontSize: 13,
        textTransform: 'uppercase',
        marginTop: 2,
        textDecoration: 'none',
    },
    authorLink: {
        fontFamily: theme.typography.headerFontFamily,
        color: '#cdcdcd',
        fontSize: 13,
        textTransform: 'uppercase',
        marginTop: 2,
        textDecoration: 'none',
        '&:hover': {
            color: theme.extendedProperties.accents.primary,
        },
    },
    tags: {
        fontSize: 13,
    },
    conversationButton: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        margin: '10px 0px 10px 0px',
        padding: '10px 0px 10px 0px',
        border: 'solid 1px ' + theme.palette.primary.linkColor,
        color: theme.palette.primary.linkColor,
    },
    cardMedia: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        width: '100%',
        height: 130,
        margin: 0,
        flexShrink: 0,
        border: 'none',
    },
    title: {
        fontSize: 22,
        fontWeight: 400,
        fontFamily: theme.typography.headerFontFamily,
        color: theme.palette.primary.textColor,
        transition: '0.3s',
        textDecoration: 'none',
        display: 'inline-block',
        '&:hover': {
            color: theme.extendedProperties.accents.primary,
        },
    },
    cardAction: {
        padding: 0,
        textAlign: 'left',
    },
    categories: {
        fontFamily: theme.typography.headerFontFamily,
        width: '100%',
        color: '#cccccc',
        padding: 0,
        margin: 0,
    },
    sideTitle: {
        fontSize: 18,
        fontWeight: 400,
    },
    sideAuthor: {
        fontSize: 14,
        color: '#b2b2b2',
        paddingTop: 4,
    },
    sideDate: {
        fontSize: 14,
        color: '#b2b2b2',
    },
    sideRank: {
        paddingRight: 20,
        paddingTop: 10,
        fontSize: 28,
        color: '#cccccc',
    },
    contentContainer: {
        backgroundColor: theme.extendedProperties.background.primary,
        padding: 25,
        marginTop: 25,
        marginBottom: 25,
        color: theme.palette.primary.textColor
    },

}));

const LoaderComponent = () => {

    let newsNum = [0,1,2,3,4,5];
    return (
        <Grid container spacing={2}>
            {newsNum.map((item, index) => {
                return (
                    <Grid item key={index} xs={12} sm={12} md={6} lg={6}>
                        <ContentLoader
                            speed={2}
                            width={480}
                            height={130}
                            viewBox="0 0 500 130"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="0" y="0" rx="2" ry="2" width="250" height="130" />
                            <rect x="265" y="0" rx="0" ry="0" width="300" height="30" />
                            <rect x="265" y="35" rx="0" ry="0" width="280" height="15" />
                            <rect x="265" y="55" rx="0" ry="0" width="240" height="15" />
                            <rect x="265" y="75" rx="0" ry="0" width="300" height="15" />
                            <rect x="265" y="95" rx="0" ry="0" width="260" height="15" />
                            <rect x="265" y="115" rx="0" ry="0" width="240" height="15" />
                        </ContentLoader>
                    </Grid>
                )
            })}
        </Grid>
    )
};

const PopularListItems = (props) => {

    const { contentList } = props;
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Typography style={{marginTop: 20}} variant="h5" component="h5" className={classes.title}>Popular Content</Typography>
            {contentList.map((content, index) => (
                <div key={index} style={{display: 'flex', flexDirection: 'row', margin: '10px 0px 10px 0px'}}>
                    <div className={classes.sideRank}>
                        {index + 1}
                    </div>
                    <div>
                        <Link className={classes.link} to={`/content/${content.itemId}/${content.slug}`}>
                            <Typography variant="h5" component="h5" className={classes.sideTitle}>{content.title}</Typography>
                        </Link>
                        <Link className={classes.link} to={`/user/${content.authorId}/${content.authorDisplayName}`}>
                            <Typography variant="h5" component="h5" className={classes.sideAuthor}>{content.authorDisplayName}</Typography>
                        </Link>
                        <Typography variant="h5" component="h5" className={classes.sideDate}><Moment fromNow>{content.publishedDtm}</Moment></Typography>

                    </div>
                </div>
            ))}
        </Grid>
    )
};

export const PopularList = (props) => {

    const [popularList, setPopularList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        async function getPopularContent() {
            try {
                const response = await fetch(
                    '/api/content/top'
                );
                const data = await response.json();
                setPopularList(data);

            } catch (e) {
                console.error(e);
            }
            finally {
                setLoading(false);
            }
        }

        getPopularContent().then(promise => {

        });
    }, []);



    if (loading) {
        return (
            <LoaderComponent/>
        )
    }
    else {
        return (
            <PopularListItems contentList={popularList}/>
        )
    }

};
