import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import {Button, LinearProgress, MenuItem, FormControlLabel, createStyles, emphasize} from '@material-ui/core';
import DraftEditor from "../editor/DraftEditor";
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import {EditorProvider, useEditorContext} from "../editor/EditorProvider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Select from "@material-ui/core/Select";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import {useParams} from "react-router-dom";
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

import {
    AttachmentIcon,
    BugIcon,
    DeleteIcon,
    DislikeIcon,
    EtcIcon,
    ExcelIcon,
    FavoriteIcon,
    FavoriteIconEmpty,
    HappyIcon,
    ImageIcon,
    LikeIcon,
    LogIcon,
    LogoutIcon,
    MailIcon,
    PDFIcon,
    PhotoIcon,
    PowerBIIcon,
    SaveIcon,
    SQLIcon,
    TextIcon,
    UnhappyIcon,
    VideoIcon,
    VisibilityIcon,
    VisibilityOffIcon,
    WordIcon,
    ZipIcon,
} from '../icons/Icons';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {useAppContext} from "../context/Provider";
import Card from "@material-ui/core/Card/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import cogoToast from 'cogo-toast';
import {BLOB_STORAGE_URI} from "../constants/Constants";

function useCallback() {
    
}

const useStyles = makeStyles((theme) => createStyles({
    
    textField: {
        color: 'black',
    },
    checkboxLabel: {
        fontSize: 16,
    },
    checkboxRoot: {
        margin: '15px 0px 15px 0px',
    },
    containerRoot: {
        flexGrow: 1,
    },
    controlPadding: {
        padding: '30px',
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    skinnyHeader: {
        fontWeight: 300,
    },
    subheader: {
        margin: '0px 0px 30px 0px',
        fontSize: '18px',
        paddingTop: '5px',
    },
    editorStyle: {
    },
    editorWrapper: {
    },
    toolbarStyles:{
        margin: '0 0 50 0',
    },

    dropzone: {
        backgroundColor: '#efefef',
        border: 'dashed 2px #cccccc',
        width: '100%',
        color: '#cccccc',
        fontWeight: 800,
        padding: 0,
        margin: '5 0 10 0',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 120,
        position: 'relative',
        boxSizing: 'border-box',
        /*
        -webkit-transition: all .15s linear;
        -o-transition: all .15s linear;*/
        transition: 'all .15s linear',
    },
    dropzoneActive: {
    },
    dropzoneReject: {
    },
    dropzoneDisabled: {
    },
    input: {
    },
    inputLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        //fontFamily: 
        fontSize: 20,
        fontWeight: 600,
        //color: #2484FF;
        //-moz-osx-font-smoothing: grayscale,
        //-webkit-font-smoothing: antialiased;
        cursor: 'pointer',
    },
    inputLabelWithFiles: {
    },
    preview: {
    },
    previewImage: {
    },
    submitButtonContainer: {
    },
    submitButton: {
    },
    
}));

const Uploader = () => {

    const classes = useStyles();
    const { id } = useParams();

    const styles = {
        dropzone: classes.dropzone,
        //dropzoneActive: classes.dropzoneActive,
        //dropzoneReject: classes.dropzoneReject,
        //dropzoneDisabled: classes.dropzoneDisabled,
        //input: classes.input,
        inputLabel: classes.inputLabel,
        //inputLabelWithFiles: classes.inputLabelWithFiles,
        //preview: classes.preview,
        //previewImage: classes.previewImage,
        //submitButtonContainer: classes.submitButtonContainer,
        //submitButton: classes.submitButton
    };

    const getUploadParams = ({ file, meta }) => {
        const url = '/api/files/upload';

        //const body = new FormData();
        const fields = {
            parentId: id,
            fileName: meta.name
        };
        const method = 'method';
        const headers = {
        };
        return { url, fields };
    };

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) };

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta));
        allFiles.forEach(f => f.remove());
    };

    console.log(styles);

    return (
        <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            accept="*"
            classNames={styles}
        />
    )
};

const Attachments = () => {
    
    const attachmentType = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        if (extension === 'sql') {
            return SQLIcon;
        }
        else if (extension === 'zip') {
            return ZipIcon;
        }
        else {
            return EtcIcon;
        }
    };

    const useStyles = makeStyles((theme) => createStyles({
        attachments: {
            backgroundColor: '#ffffff',
            border: 'solid 2px #cccccc',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginBottom: 40,
        },
        attachmentBox: {
            padding: '15px 15px 15px 15px',
        },
        classes: {
            padding: 10,
            color: '#9a9a9a'
        },
        title: {
            fontSize: 14,
            fontWeight: 600,
            fontFamily: [
                'Work Sans',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            color: '#cccccc',
            position: 'relative',
            top: 6,
            left: 10,
        },
        visibleItem: {
            width: 120,
            height: 120,
            borderRadius: 3,
            alignItems: 'center',
            paddingTop: 6,
            backgroundColor: 'transparent',
            border: 'solid 1px #efefef',
            display: 'block',

            /*
            "&:hover, &:focus": {
                backgroundColor: ({ hoverBackgroundColor, backgroundColor }) =>
                    hoverBackgroundColor
                        ? hoverBackgroundColor
                        : emphasize(backgroundColor, 0.08)
            },
            "&:active": {
                backgroundColor: ({ hoverBackgroundColor, backgroundColor }) =>
                    emphasize(
                        hoverBackgroundColor ? hoverBackgroundColor : backgroundColor,
                        0.12
                    )
            }*/
        },
        invisibleItem: {
            width: 120,
            height: 120,
            borderRadius: 3,
            alignItems: 'center',
            paddingTop: 6,
            backgroundColor: 'transparent',
            border: 'solid 1px #efefef',
            
        },
        filename: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'block',
            fontSize: 12,
            color: '#333333',
            marginTop: 6,
        }
    }));
    
    const [state, dispatch] = useEditorContext();
    const classes = useStyles();
    const { id } = useParams();

    const attachmentButtonClick = (e) => {
        e.preventDefault();
    };

    if (state.attachments !== null && state.attachments.length > 0) {
        return (
            <div className={classes.attachments}>
                <div className={classes.title}>
                    ATTACHMENTS
                </div>
                <div className={classes.attachmentBox}>
                {state.attachments.map((attachment, index) => {

                    let itemStyle;
                    let itemIcon;
                    let visibilityIcon;
                    
                    if (attachment.visibility) {
                        itemStyle = classes.visibleItem;
                        itemIcon = <AttachmentIcon style={{color: 'red'}} size={72}/>;
                        visibilityIcon = <VisibilityIcon/>;
                    }
                    else {
                        itemStyle = classes.invisibleItem;
                        itemIcon = <ImageIcon style={{color: '#cccccc'}} size={72}/>;
                        visibilityIcon = <VisibilityOffIcon style={{justifySelf: 'right', alignSelf: 'right', color: '#888888',}}/>;
                    }
                    
                    const banana = () => {
                        return (
                            <React.Fragment>
                                {visibilityIcon}
                                <DeleteIcon/>
                            </React.Fragment>
                        )
                    };
                    
                    return (
                        <button key={index} className={itemStyle} data-attachment={attachment.attachmentId} 
                                onMouseDown={attachmentButtonClick}
                        >
                            <div>
                                {itemIcon}
                                <span className={classes.filename}>{attachment.filename}</span>
                            </div>
                        </button>
                    )}
                )}
                </div>
            </div>
            
        )
    }
    else {
        return null;
    }
};

const validationSchema = Yup.object({
    title: Yup.string("Enter a title")
        .required("An article title is required."),
});

const BannerDialog = (props) => {

    const useStyles = makeStyles((theme) => createStyles({

        activeBannerSelection: {
            border: 'solid 1px red',
            margin: 0,
        },
        inactiveBannerSelection: {
            margin: 1,
        },
    }));

    const [state, dispatch] = useEditorContext();
    const classes = useStyles();
    const [mediaList, setMediaList] = React.useState([]);
    const [mediaPage, setMediaPage] = React.useState([]);
    const [mediaCategory, setMediaCategory] = React.useState([]);
    const [mediaSubcategory, setMediaSubcategory] = React.useState([]);
    const [mediaCategoryList, setMediaCategoryList] = React.useState([]);
    const [mediaSubcategoryList, setMediaSubcategoryList] = React.useState([]);

    const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
    
    const [temporaryMediaSelection, setTemporaryMediaSelection] = React.useState(false);
    
    const openUploadDialog = () => {
        setUploadDialogOpen(true);
    };

    const setBannerImage = (value) => {

        setTemporaryMediaSelection(value);
    };
    
    const commitBannerImage = (e) => {
        dispatch({type: 'SET_BANNER_ID', value: temporaryMediaSelection});
        props.handleClose(e);
    };


    useEffect(() => {
        
        async function getMedia() {
            let apiUri = `/api/files/media-library?cat=${mediaCategory}`;
            let options= {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                referrer: 'no-referrer',
            };
            try {
                //dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: true });
                const response = await fetch(apiUri, options);
                const data = await response.json();
                setTemporaryMediaSelection(state.bannerId);
                setMediaList(data);
            } catch (e) {
                console.error(e);
            }
            finally {
                //dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: false });
            }
        }
        if (props.open){
            getMedia();
        }
    }, [props.open]);

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Banner Image</DialogTitle>
            <DialogContent>
                
                <Grid container spacing={2}>
                    {mediaList.map((row, index) => {
                        let activeClass;
                        if (temporaryMediaSelection === row.itemId) {
                            activeClass = classes.activeBannerSelection;
                        }
                        else {
                            activeClass = classes.inactiveBannerSelection;
                        }
                        return(
                        <Grid item key={index} sm={4} md={4} lg={4} xl={4}>
                            
                            <Card className={classes.root}>
                                <CardActionArea
                                    id={row.itemId}
                                    value={row.itemId}
                                    className={activeClass}
                                    onClick={e => setBannerImage(row.itemId)}
                                >
                                    <CardMedia
                                        component="img"
                                        alt={row.filename}
                                        height="140"
                                        image={BLOB_STORAGE_URI + row.bannerThumbnailUri}
                                    />
                                </CardActionArea>
                            </Card>
             
                        </Grid>
                        )
                    })}
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={e => commitBannerImage(e)} color="primary">
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
    )
};

const ContentForm = (props) => {

    const [state, dispatch] = useEditorContext();
    const { id } = useParams();
    
    useEffect(() => {
        dispatch({type: 'SET_CONTENT_ID', value: id})
    }, []);
    
    const handleDetailChange = (event) => {
        let change = {};
        change[event.target.name] = event.target.value;
        dispatch({type: 'HANDLE_CHANGE', value: change})
    };
   
    const handleCategoryChange = (event) => {
        dispatch({ type: 'SET_CATEGORY', value: event.target.value });
        getSubCategories(event.target.value).then(r => { });
    };

    const handleSubCategoryChange = (event) => {
        dispatch({ type: 'SET_SUBCATEGORY', value: event.target.value })
    };

    const handleSubmit = (event) => {

        const currentContent = state.editorState.getCurrentContent();
        const plainTextContent = currentContent.getPlainText();
        const rawDraftContentState = JSON.stringify(convertToRaw(currentContent));
        
        const data = {
            itemId: parseInt(state.contentId),
            categoryId: state.category,
            subcategoryId: state.subcategory,
            title: state.title,
            slug: state.slug,
            content: rawDraftContentState,
            contentText: plainTextContent,
            bannerId: state.bannerId,
        };

        const apiUri = "/api/content/save";

        
        
        fetch(apiUri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json; charset=utf-8',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(data),
        }).then(
            
        ).then(
            data => {
                cogoToast.success('Save Complete!')
                
            }
        )
    };

    useEffect(() => {
        async function getSavedContent() {
            try {
                
                const response = await fetch(
                    `/api/content/retrieve?itemId=${id}`
                );
                
                const data = await response.json();
                dispatch({ type: 'SET_CATEGORY', value: data.categoryId });

                if (data.categoryId > 0){
                    getSubCategories(data.categoryId).then(r => { });
                    //getSubCategories(data.categoryId).then(r => { //dispatch({ type: 'SET_SUBCATEGORY', value: data.subCategoryId }); });
                }
                
                if (data.itemContent) {
                    data.editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(data.itemContent)));
                }
                else {
                    data.editorState = EditorState.createEmpty();
                }
                
                dispatch({ type: 'LOAD_CONTENT', value: data });

            } catch (e) {
                console.error(e);
            }
        }
        getSavedContent().then(promise => {  });
    }, []);

    async function getCategories() {
        try {
            const response = await fetch(
                '/api/content/categories'
            );
            const data = await response.json();

            dispatch({ type: 'SET_CATEGORIES', value: data });
        } catch (e) {
            console.error(e);
        }
    }

    async function getSubCategories(categoryId) {
        if (categoryId > 0) {
            try {
                const response = await fetch(
                    `/api/content/subcategories?categoryId=${categoryId}`
                );
                const data = await response.json();
                return (dispatch({ type: 'SET_SUBCATEGORIES', value: data }))

            } catch (e) {
                console.error(e);
            }
        }
    }
    
    const handleBannerDialog = (e, active) => {
        e.preventDefault();
        setBannerImageDialogOpen(active);
    };

    const [bannerImageDialogOpen, setBannerImageDialogOpen] = useState(false);
    
    useEffect(() => {
        getCategories().then(promise => {  });
    }, []);
    
    return (
    <form onSubmit={handleSubmit}>
        <BannerDialog open={bannerImageDialogOpen} handleClose={e => handleBannerDialog(e,false)}/>
        <Grid
            container
            style={{marginTop: 4}}
            spacing={4}
        >
            <Grid item sm={5} md={5} lg={5}
                  style={{paddingTop: 0}}>

                <FormControl
                    style={{marginTop: 16, marginBottom: 8,}}
                    fullWidth>
                    <InputLabel>Category</InputLabel>
                    <Select
                        labelId="categoryLabel"
                        id="category"
                        value={state.category}
                        onChange={handleCategoryChange}
                    >
                        {state.categories.map((item, index) => (
                            <MenuItem key={index} value={item.categoryId}>
                                {item.category}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField type="text"
                           name="title"
                           label="Title"
                           variant="standard"
                           margin="normal"
                           fullWidth={true}
                           value={state.title}
                           onChange={handleDetailChange}
                           InputLabelProps={{
                               shrink: true,
                           }}
                />

                <Grid item sm={6} md={6} lg={6}
                      style={{paddingTop: 0}}>
                    <TextField type="text"
                               name="contentId"
                               label="Content ID"
                               variant="standard"
                               disabled
                               margin="normal"
                               fullWidth={true}
                               value={state.contentId}
                               onChange={undefined}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                    />
                </Grid>
                
            </Grid>
            <Grid item sm={5} md={5} lg={5}
                  style={{paddingTop: 0}}>
                <FormControl
                    style={{marginTop: 16, marginBottom: 8,}}
                    fullWidth>
                    <InputLabel>SubCategory</InputLabel>
                    <Select
                        labelId="subcategoryLabel"
                        id="subcategory"
                        value={state.subcategory}
                        onChange={handleSubCategoryChange}
                    >
                        {state.subcategories.map((item, index) => (
                            <MenuItem key={index} value={item.subCategoryId}>
                                {item.subCategory}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField type="text"
                           name="slug"
                           label="Slug"
                           variant="standard"
                           margin="normal"
                           fullWidth={true}
                           value={state.slug}
                           onChange={handleDetailChange}
                           InputLabelProps={{
                               shrink: true,
                           }}
                />
            </Grid>

            <Grid item sm={2} md={2} lg={2}
                  style={{paddingTop: 0}}>

                <div className={"MuiFormControl-root MuiFormControl-fullWidth"}
                     style={{marginTop: 16, marginBottom: 8}}>
                    <label
                        className={"MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"}>
                        Banner Image
                    </label>
                    <button 
                        onClick={(e) => handleBannerDialog(e, true)}
                        style={{border: 'none', cursor: 'pointer', backgroundColor: '#cccccc', height: 100, width: '100%', marginTop: 20, backgroundSize: 'cover', backgroundImage: `url(${state.bannerUri})`}}>
                    </button>
                </div>
            </Grid>
        </Grid>
            
        <Grid
            container
            spacing={4}
        >
            <DraftEditor
                saveForm={handleSubmit}
            />
            <Attachments/>
            <Uploader/>
        </Grid>
        
        <br />
        {state.isSubmitting && <LinearProgress />}
        <br />
        
        <Button
            name={"submit"}
            variant="contained"
            color="primary"
            disabled={state.isSubmitting}
            onClick={handleSubmit}
        >
            Save
        </Button>
    </form>
)};

export default function WebEditor () {
    const classes = useStyles();
    return (
        <React.Fragment>
                <Grid
                    container
                    spacing={4}
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <EditorProvider>
                            <ContentForm/>
                        </EditorProvider>
                    </Grid>
                </Grid>
        </React.Fragment>
    )
}
