import {useAppContext} from "../../context/Provider";
import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import ContentLoader from "react-content-loader";
import Select from "@material-ui/core/Select/Select";
import Card from "@material-ui/core/Card/Card";
import {BLOB_STORAGE_URI} from "../../constants/Constants";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import {UploadImageDialog} from "./AdministrationDrafts";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => createStyles({
    
    drawerList: {
        marginTop: theme.spacing(1),
        width: '100%',
        display: 'block',
        float: 'left',
    },
    
}));


export const AdministrationMedia = (props) => {

    const [state, dispatch] = useAppContext();
    const classes = useStyles();
    const [mediaList, setMediaList] = React.useState([{}]);
    const [mediaPage, setMediaPage] = React.useState([{}]);
    const [mediaCategory, setMediaCategory] = React.useState(-1);
    const [mediaSubcategory, setMediaSubcategory] = React.useState([{}]);
    const [mediaCategoryList, setMediaCategoryList] = React.useState([{}]);
    const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
    const openUploadDialog = () => {
        setUploadDialogOpen(true);
    };
    const [loadingMedia, setLoadingMedia] = React.useState(true);

    GetMedia();

    const MediaLoader = () => {

        if (loadingMedia) {
            const group = [1,2,3,4,5,6,7,8];
            return (
                <Grid
                    container
                    spacing={4}
                >
                    {group.map((row, index) => (
                        <Grid key={index} item sm={4} md={3} lg={3} xl={2}>
                            <ContentLoader
                                speed={2}
                                width={220}
                                height={170}
                                viewBox="0 400 4400"
                                backgroundColor="#f3f3f3"
                                foregroundColor="#ecebeb"
                            >
                                <rect x="0" y="0" rx="2" ry="2" width="200" height="120" />
                                <rect x="0" y="125" rx="0" ry="0" width="200" height="30" />
                            </ContentLoader>
                        </Grid>
                    ))}
                </Grid>
            )
        }
        else {
            return (null);
        }

    };

    function GetMedia() {
        let apiUri = `/api/files/media-library?cat=${mediaCategory}`;
        let options= {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            //body: JSON.stringify(data),
            referrer: 'no-referrer',
        };
        useEffect(() => {
            async function GetMedia() {
                try {
                    dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: true });
                    const response = await fetch(apiUri, options);
                    const data = await response.json();
                    setMediaList(data);
                } catch (e) {
                    console.error(e);
                }
                finally {
                    dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: false });
                    setLoadingMedia();
                }
            }
            GetMedia().then(promise => {
            });
        }, []);
    }

    return (
        <React.Fragment>
            <UploadImageDialog open={uploadDialogOpen} handleClose={() => {setUploadDialogOpen(false)}}/>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div>
                    <h1>Media</h1>
                </div>
                <div style={{flexGrow: 1}}>
                </div>
                <div style={{alignSelf: 'center'}}>
                    <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={mediaCategory}
                        onChange={setMediaCategory}
                        style={{marginRight: 10, width: 200,}}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                    <Button variant="contained" color="primary" onMouseDown={() => { setUploadDialogOpen(true)}} open={uploadDialogOpen}>Upload New</Button>
                </div>
            </div>

            <MediaLoader/>

            <Grid
                container
                spacing={4}
            >
                {mediaList.map((row, index) => (
                    <Grid item key={index} sm={4} md={3} lg={3} xl={2}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt={row.filename}
                                    height="140"
                                    src={`${BLOB_STORAGE_URI + row.bannerThumbnailUri}`}
                                    title="Contemplative Reptile"
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    )
};