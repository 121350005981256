import React, {useState, setState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { NavLink } from 'react-router-dom';
import {Formik} from "formik";
import * as Yup from "yup";

import createStyles from "@material-ui/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { Redirect } from 'react-router-dom';



const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        flexBasis: 200,
    },
    loginField: {
        paddingBottom: 20,
    }
}));

export default function FormLogin() {
    
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [formData, setFormData] = useState('');
    
    const classes = useStyles();
    
    const handleClose = () => {
        
    };
    
    const handleSubmit = () => {
        
    };

    const handleChange = prop => event => {
        setState({ [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setState(state => ({ showPassword: !state.showPassword }));
    };

    const handleMicrosoftLogin = () => {
            window.location.href = '/auth/login';
    };

    const initialState = formData;
            
    return (
        
        <Grid container 
              spacing={3} 
              justify="center"
              alignItems="center"
              alignContent="center">

            <Grid item xs={12} style={{textAlign: 'center', paddingTop: 20}}>
                <TextField
                    fullWidth
                    label="E-mail Address"
                    className={classes.loginField}
                />

                <TextField
                    fullWidth
                    label="Display Name"
                    className={classes.loginField}
                />

                <TextField
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    value={password}
                    onChange={setPassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            
          
            </Grid>

            <Grid item xs={6} style={{textAlign: 'center', fontSize: 14,}}>
                <NavLink to="/">
                    Remember Me
                </NavLink>
            </Grid>
            
            <Grid item xs={6} style={{textAlign: 'center', paddingTop: 20, fontSize: 12,}}>
                <NavLink to="/">
                    Forgot your password?
                </NavLink>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'center'}}>
                <Button variant="contained" onClick={handleClose} color="primary" fullWidth>
                    Subscribe
                </Button>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'center', fontSize: 12,}}>
                ---------- OR ----------
            </Grid>

            <Grid item xs={6} style={{textAlign: 'center', fontSize: 12,}}>
                <Button variant="contained" onClick={handleClose} color="primary" fullWidth>
                    GOOGLE
                </Button>
            </Grid>

            <Grid item xs={6} style={{textAlign: 'center', fontSize: 12,}}>
                <Button variant="contained" onClick={handleMicrosoftLogin} color="primary" fullWidth>
                    MICROSOFT
                </Button>
            </Grid>
            
        </Grid>
    );
}
