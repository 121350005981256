export function appReducer(state: State, action: Action) {

    const { type, value } = action;
    
    
    // HELLO FROM APP REDUCER
    console.log('ACTION:');
    console.log(action);
    console.log('STATE:');
    console.log(state);
    console.log('VALUE:');
    console.log(value);
    
    switch (type) {
        case 'SET_AUTH_STATUS':
            return {
                ...state,
                authenticated: value.authenticated,
                identifier: value.identifier,
                username: value.username,
                userId: value.userId,
                email: value.email,
                allowedActions: value.allowedActions
            };
        case 'SET_TITLE':
            return {
                ...state,
                title: value,
            };
        case 'TOGGLE_USER_MENU':
            return {
                ...state,
                userMenuOpen: !(state.userMenuOpen),
                activeElement: value,
            };
        case 'CLEAR_REDIRECT':
            return {
                ...state,
                redirect: false,
                redirectUri: null,
            };
        case 'SET_REDIRECT':
            return {
                ...state,
                redirect: true,
                redirectUri: value,
            };
        
        case 'TOGGLE_CONTENT_MENU':
            return {
                ...state,
                contentMenuOpen: true,
            };
        case 'SET_CATEGORIES':
            return {
                ...state,
                categories: value,
            };
        case 'TOGGLE_AUTH_MODAL':
            return {
                ...state,
                authModalOpen: !(state.authModalOpen)
            };
        case 'OPEN_AUTH_MODAL':
            return {
                ...state,
                authModalOpen: true,
            };
        case 'CLOSE_AUTH_MODAL':
            return {
                ...state,
                authModalOpen: false,
            };
        case 'SET_AUTH_MODAL_TAB':
            
            // 'Login' 'SignUp'
            return {
                ...state,
                authModalOpen: true,
                authModalTab: value
            };
            
            
        case 'TOGGLE_PROFILE_MENU':
            return {
                ...state,
                profileMenuOpen: !state.profileMenuOpen,
            };
            
            
        case 'CLOSE_MOBILE_MENU':
            return {
                ...state,
                mobileMenuOpen: false,
            };

        case 'OPEN_MOBILE_MENU':
            return {
                ...state,
                mobileMenuOpen: true,
            };
            
        case 'OPEN_DRAWER':
            return {
                ...state,
                drawerOpen: true,
            };

        case 'CLOSE_DRAWER':
            return {
                ...state,
                drawerOpen: false,
            };

        case 'TOGGLE_DRAWER':
            return {
                ...state,
                drawerOpen: !(state.drawerOpen),
            };
        case 'HANDLE_LOGOUT':
            return {
                ...state,
                authenticated: false,
                identifier: null,
                username: null,
                email: null,
                allowedActions: [],
            };

        case 'SET_ADMINISTRATION_LOADING':
            return {
                ...state,
                administrationLoading: value,
            };

        case 'SET_EDITOR':
            return {
                ...state,
                editorState: value,
            };
        case 'SET_EDITOR_PLUGINS':
            return {
            ...state,
                editorPlugins: value
            };
            
        case 'SET_CONTENT_LIST':
        {
            /*
            let mappedContentList = {
                    itemId: value.itemId,
                    title: value.title,
                    slug: value.slug,
                    createdDtm: value.createdDtm,
                    modifiedDtm: value.modifiedDtm,
                    publishedDtm: value.publishedDtm,
                    categoryId: value.categoryId,
                    category: value.category,
                    subCategoryId: value.subCategoryId,
                    authorId: value.authorId,
                    authorDisplayName: value.authorDisplayName,
                    bannerId: value.bannerId,
                    bannerUri: value.bannerUri,
                    bannerThumbnailUri: value.bannerThumbnailUri,
                };
            */
            return {
                ...state,
                contentList: value
            };
        }
        
        case 'SET_SEARCH_TEXT':
            return {
                ...state,
                search: value,
            };

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}