import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { Layout } from './../components/Layout';

const styles = theme => ({

});

export default function Lab () {
    return (
        <Layout>
            <div> hi there </div>
        </Layout>
    );
}





