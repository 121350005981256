import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { Send as SendIcon, 
    Close as CloseIcon, 
    Drafts as DraftsIcon, 
    MoveToInbox as InboxIcon,
    Person as PersonIcon,
} from '@material-ui/icons';


import {useAppContext} from "../context/Provider";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {useNavigate} from "react-router";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);



export default function UserMenu(props) {
    
    const [state, dispatch] = useAppContext();
    const [openLogout, setOpenLogout] = React.useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        dispatch({type: 'TOGGLE_USER_MENU', value: null})
    };
    
    
    const handleLogoutPromptClose = () => {
        setOpenLogout(false);
    };

    const handleLogoutClose = () => {
        setOpenLogout(false);
    };
    
    const handleProfile = () => {
        
    };

    
    
    const handleLogoutPrompt = () => {
        setOpenLogout(true);
        handleClose();
    };
    
    return (
        <React.Fragment>
            <StyledMenu
                id="customized-menu"
                anchorEl={state.activeElement}
                keepMounted
                open={state.userMenuOpen}
                onClose={handleClose}
            >
                <StyledMenuItem
                    onClick={handleProfile}>
                    <ListItemIcon>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                </StyledMenuItem>
                <StyledMenuItem 
                    onClick={handleLogoutPrompt}>
                    <ListItemIcon>
                        <CloseIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText 
                        primary="Logout"
                    />
                </StyledMenuItem>
            </StyledMenu>
            
            <Dialog
                open={openLogout}
                onClose={handleLogoutClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Logout"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to logout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogoutClose} color="primary">
                        Cancel
                    </Button>
                    <Button href={'/auth/logout'} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}