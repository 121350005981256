import React, {useEffect, useRef} from 'react';
import {
    EditorState,
    RichUtils,
    getDefaultKeyBinding,
    Modifier,
    convertToRaw, convertFromRaw
} from 'draft-js';

import Editor from 'draft-js-plugins-editor';

import {InlineControl, StyleControls} from './StyleControls/StyleControls';

import {useEditorContext} from './EditorProvider';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {useParams} from 'react-router-dom';
import {plugins, decorators} from './plugins';
import {BLOB_STORAGE_URI} from "../constants/Constants";
import Prism from 'prismjs';
import { customStyleMap } from './CustomStyleMap';

const useStyles = makeStyles((theme) => createStyles({
    
    editorContainer: {
        width: '100%',
        minHeight: 250,
        boxSizing: 'border-box',
        border: '2px solid #ddd',
        cursor: 'text',
        padding: 16,
        borderRadius: 2,
        marginBottom: '2em',
        //boxShadow: 'inset 0px 1px 1px -1px #ABABAB',
        background: '#fefefe'
    },
    editor: {
        cursor: 'text',
        padding: 16,
        marginBottom: '2em',
    },
    publicDraftEditorcontent: {
        minHeight: 140
    },
    headlineButtonWrapper: {
        display: 'inline-block',
    },
    headlineButton: {
        background: '#fbfbfb',
        color: '#888',
        fontSize: 18,
        border: 0,
        paddingTop: 5,
        verticalAlign: 'bottom',
        height: 34,
        width: 36
    },
}));

export const DraftEditor = ({ readOnly, classList, saveForm }) => {

    const [state, dispatch] = useEditorContext();
    const { id } = useParams();
    const classes = useStyles();
    const editorRef = useRef(null);
    
    /*
    useEffect(() => {
        dispatch({type: 'SET_EDITOR_PLUGINS', value: plugins});
        dispatch({type: 'SET_EDITOR_DECORATORS', value: decorators});
        Prism.highlightAll()
    }, plugins);*/
    
    useEffect(() => {
        Prism.highlightAll()
    })

    const getBlockStyle = (block) => {
        switch (block.getType()) {
            case 'code-block':
                return 'language-sql';
            default:
                return null;
        }
    };

    const keyBinding = (e) => {
        let currentState = state.editorState
        const selection = state.editorState.getSelection();
        const block = state.editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey());
        const blockType = currentState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();
        
        if (e.keyCode === 13 && e.shiftKey) 
        {
            return 'soft-break';
        }
        else if (e.key === 'Tab') {
            if(blockType === "unordered-list-item" || blockType === "ordered-list-item")
            {
                handleChange(RichUtils.onTab(e, currentState, 3));
            }
            else
            {
                const newBlockState = Modifier.replaceText(
                    currentState.getCurrentContent(),
                    currentState.getSelection(),
                    '\t'
                );
                //dispatch({type: 'SET_EDITOR_STATE', value: EditorState.push(currentState, newBlockState, 'insert-characters')})
                dispatch({type: 'SET_EDITOR_STATE', value: EditorState.push(currentState, newBlockState, 'change-block-data')})
                //handleChange(EditorState.push(currentState, newBlockState, 'insert-characters'))
                return 'handled';
            }
        }
        else if (e.key === 'Tab' && block.getType() === 'code-block') 
        {
            return 'code-tab';
        }
        else if (e.key === 's' && e.ctrlKey) 
        {
            return 'save-shortcut';
        }
        
        return getDefaultKeyBinding(e);
    };

    const handleKeyCommand = (command, state) => {
        
        const newState = RichUtils.handleKeyCommand(state, command);
        
        if (command === 'code-block') {
            dispatch({type: 'SET_EDITOR_STATE', value: RichUtils.toggleBlockType(state, 'code-block')});
            return 'handled';
        }
        else if (command === 'soft-break') {
            dispatch({type: 'SET_EDITOR_STATE', value: RichUtils.insertSoftNewline(state)});
            return 'handled';
        }
        /*
        else if (command === 'code-tab') {
            const currentState = state;
            const newBlockState = Modifier.replaceText(
                currentState.getCurrentContent(),
                currentState.getSelection(),
                '\t');
            dispatch({type: 'SET_EDITOR_STATE', value: EditorState.push(currentState, newBlockState, 'insert-characters')})
            return 'handled';
        }
              
        else if (command === 'code-tab') {
            const currentState = state;
            const newBlockState = Modifier.replaceText(
                currentState.getCurrentContent(),
                currentState.getSelection(),
                '\t');
            dispatch({type: 'SET_EDITOR_STATE', value: EditorState.push(currentState, newBlockState, 'insert-characters')})
        }*/
        else if (command === 'save-shortcut') {
            // Perform a request to save your contents, set
            // a new `editorState`, etc.
            saveForm();
        }
        
        
        if (newState) {
            (
                dispatch({type: 'SET_EDITOR_STATE', value: newState})
            );
            return 'handled';
        }
        return 'not-handled';
    };

    const toggleBlockType = (type) => {
        (
        dispatch({type: 'SET_EDITOR_STATE', value: RichUtils.toggleBlockType(state.editorState, type)})
        );
    };

    const toggleStyleType = (type) => {
        (
            dispatch({type: 'SET_EDITOR_STATE', value: RichUtils.toggleInlineStyle(state.editorState, type)})
        );
    };

    const handleChange = (newEditorState) => {
        
        const selection = newEditorState.getSelection();
        const block = newEditorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey());
        const currentContent = state.editorState.getCurrentContent();
        const newContent = newEditorState.getCurrentContent();
        const blockType = block.getType();
        
        if (
            blockType === 'code-block' &&
            !newContent.equals(currentContent)
        ) 
        {
            const data = block.getData().merge({ language: 'sql' });
            const newBlock = block.merge({ data });
            const newContentState = newEditorState.getCurrentContent().merge({
                blockMap: newEditorState
                    .getCurrentContent()
                    .getBlockMap()
                    .set(selection.getStartKey(), newBlock),
                selectionAfter: selection
            });
            dispatch({type: 'SET_EDITOR_STATE', value: EditorState.push(newEditorState, newContentState, 'change-block-data')})
        } 
        else (
            dispatch({type: 'SET_EDITOR_STATE', value: newEditorState})
        );
    };
    
    const focusEditor = () => {
        editorRef.current.focus()
    }
    
    return (
        <React.Fragment>
            {!readOnly && (
                <React.Fragment>
                    <StyleControls
                        type="button"
                        toggleBlock={toggleBlockType}
                        toggleStyle={toggleStyleType}
                    />
                </React.Fragment>
            )}
            <div
                className={classes.editorContainer}
                onClick={() => focusEditor()}
            >
                <Editor
                    className={classes.editor}
                    editorState={state.editorState}
                    plugins={plugins}
                    ref={editorRef}
                    readOnly={readOnly}
                    decorators={decorators}
                    onChange={handleChange}
                    keyBindingFn={keyBinding}
                    blockStyleFn={getBlockStyle}
                    handleKeyCommand={handleKeyCommand}
                    disabled={state.isSubmitting}
                    customStyleMap={customStyleMap}
                    spellCheck={true}
                />
            </div>
            <InlineControl/>
        </React.Fragment>
    );
};

export default DraftEditor;