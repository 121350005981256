import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({

    container: {
        paddingTop: theme.spacing(2),
        backgroundColor: theme.extendedProperties.background.primary,
        paddingBottom: theme.spacing(4),
        minHeight: '85vh',
    },
    item: {
        height: '100%',
        width: '100%',
        flex: 1,
    },
    innerContainer: {

    },
}));

export const Layout = (props) => {
    
    const classes = useStyles();
    const { children, suppressPadding } = props;
    
    return (
        <Grid container justify="center" className={classes.container}>
            <Grid item className={classes.innerContainer} xs={11}>
                {children}
            </Grid>
        </Grid>
    )
};