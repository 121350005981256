import React from "react";
import { Layout } from './../components/Layout';

export const NotFound = (props) => {
    return (
       (
        <Layout>

        </Layout>
        )
    );
};


