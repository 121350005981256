import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Stars from './../img/banner/stars.png';
import {css} from "linaria";

const animatedBackground = css`
    @keyframes starMovement {
        0% { background-position: 0px 0; }
        100% { background-position: 0px 509px; }
    }
    animation: starMovement 10s linear infinite
`;

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroUnit: {
        backgroundColor: '#aedcd2',//theme.palette.background.paper,
        //backgroundImage: `url(${Background})`,
    },
    heroContent: {
        maxWidth: 600,
        paddingTop: 30,
        margin: '0 auto',
        width: '100%',
        height: '200px',
        overflow: 'hidden',
        color: '#ffffff'
    },

    heroBackground: {
        background: 'radial-gradient(ellipse at bottom, #19163e 0%, #000000 100%)',
        width: '100%',
        height: '200px',
        padding: '0px',
        zIndex: 5,
        position: 'relative',
    },
    animatedBackground: {
        margin: '0 auto',
        background: 'url(' + Stars + ')',
        backgroundRepeat: 'repeat',
        width: '100%',
        height: '200px',
        overflow: 'hidden',
        position: 'absolute',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    cardGrid: {
        padding: `${theme.spacing(8)}px 0`,
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        //backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    link: {
        color: "#c3e475",
    },
    overrides: {
        Link: {
            text: {
                color: "#c3e475",
            },
            link: {
                color: "#c3e475",
            },

        },
    },
    search: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: 500,
        background: '#ffffff',
        borderRadius: 5,
        transition: theme.transitions.create(['border-color', 'box-shadow']),



        borderColor: 'green !important',
        '&:focus': {
            boxShadow: `${(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: '#9c27b0',
        },

        '& label.Mui-focused': {
            color: '#9c27b0',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#9c27b0',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#9c27b0',
            },
            '&:hover fieldset': {
                borderColor: '#9c27b0',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#9c27b0',
            },
        },
    },
    input: {
        color: '#000000',
        padding: '0 5 0 5',
    }
});

function AboutBanner(props) {
    const { classes } = props;

    return (
        <React.Fragment>
            <div className={classes.heroUnit}>
                <div className={classes.heroBackground}>

                    <div className={classes.animatedBackground + ' ' + animatedBackground}>
                        <div className={classes.heroContent}>
                            <Typography component="h1" variant="h2" align="center" gutterBottom>
                                About
                            </Typography>

                            <Typography variant="h6" align="center" paragraph>
                                Meet the team and learn about what we're doing.
                            </Typography>
                            <div>
                                <Grid container spacing={2} justify="center">

                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withStyles(styles)(AboutBanner);