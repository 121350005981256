import {Link} from "react-router-dom";
import React from "react";
import Typography from "@material-ui/core/Typography";

export const AuthorLink = (props) => {
    
    // requires authorName, authorId
    
    let authorFormattedName = props.authorName.replace(/\s/g, '-').toLowerCase();

    return (
        <Link className={props.style} to={`/user/${props.authorId}/${authorFormattedName}`}>
            <Typography gutterBottom variant="h5" component="h2" className={props.style}>
                {props.authorName}
            </Typography>
        </Link>
    )
}