import React, {useEffect} from "react";
import { Layout } from './../components/Layout';
import StaticPlaceholder from "../img/placeholder/static.gif";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {useParams} from "react-router-dom";
import {useAppContext} from "../context/Provider";

const useStyles = makeStyles((theme) => createStyles({
    contentContainer: {
        backgroundColor: theme.extendedProperties.background.primary,
        padding: 25,
        marginTop: 25,
        marginBottom: 25,
        color: theme.palette.primary.textColor
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3)
        },
        textAlign: "center",
        color: theme.palette.primary.textColor,
        backgroundColor: theme.extendedProperties.background.primary //theme.palette.text.secondary
    },

    layout: {
        width: "auto",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            width: 1100,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    cardGrid: {
        padding: `${theme.spacing(0)}px 0`
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.extendedProperties.foreground.primary
    },
    cardMediaz: {
        padding: 0,
        margin: 0,

        height: 250,
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'tile',
        imageRendering: 'pixelated',
    },
    cardContent: {
        flexGrow: 1,
        typography: {
            color: theme.palette.primary.textColor
        },
        backgroundMedia: `url('${StaticPlaceholder}')`,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6)
    },

    repeatBg: {
        height: 250,
        backgroundRepeat: 'repeat',
        backgroundSize: 'auto',
    },
    slideContainer: {
        height: 200,
        width: 400
    },
    paddedTop: {
        alignContent: 'center',
        paddingTop: theme.spacing(2),
    },
    spaceBanner: {
        background: 'url(../images/stars.png) repeat',
        backgroundAttachment: 'fixed',
        width: '100%',
        height: '400px',
        animation: 'animatedBackground linear 45s infinite',
        overflow: 'hidden',
    },

    articleInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        
    },

    authorIcon: {
        height: 50,
        width: 50,
        marginRight: 10,
        backgroundColor: 'red',
        
    }
    
    
    
    
    
}));


export default function Article () {

    const classes = useStyles();
    const { id } = useParams();
    const [state, dispatch] = useAppContext();

    
    return (
        <Layout>
        </Layout>
    );
}





