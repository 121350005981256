import {useAppContext} from "../../context/Provider";
import React from "react";
import WebEditor from "../WebEditor";

export const AdministrationRoles = (props) => {
    const [state, dispatch] = useAppContext();
    return (
        <React.Fragment>
            <WebEditor/>
        </React.Fragment>
    )
};