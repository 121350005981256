import React from "react";
import {useEditorContext} from "../editor/EditorProvider";

function PropsDebugger() {

    const [state, dispatch] = useEditorContext();
    
    return(
        <React.Fragment>
            <div style={{clear: 'both', width: '100%', height:20}}>
            </div>
            <div style={{clear: 'both',}}>
        
    
                <pre
                    style={{
                    background: '#f6f8fa',
                    fontSize: 13,
                    padding: 5,
                    }}
                >
                    <strong>state</strong> ={' '}
                    {JSON.stringify(state, null, 2)}
                    
                </pre>
            </div>
        </React.Fragment>
    )

}


export default (PropsDebugger);