import {ColorInactiveLikeIcon, ColorLikeIcon} from "../icons/Icons";
import React from "react";


export const AdaptiveLikeIcon = (props) => {
    
    const { liked, size } = props;
    
    if (!liked) {
        return (
            <ColorInactiveLikeIcon size={size}/>
        )
    }
    else {
        return (
            <ColorLikeIcon size={size}/>
        )
    }
}