import React, {useEffect} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/Drawer';
import AuthenticationModal from './../components/authentication/AuthenticationModal';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MainDrawerContents from './../components/appBar/MainDrawerContents';
import {Link, NavLink, useNavigate} from 'react-router-dom';
import { DIRECTORY_MAIN } from './../data/directory';
import { useAppContext } from "./../context/Provider";
import UserMenu from "./UserMenu";
import NavBarBackground from './../img/navbarbg.png';

const useStyles = makeStyles((theme) => createStyles({

    drawerRoot: {
        position: 'fixed !important',
        zIndex: theme.zIndex.appBar - 1 + ' !important',
    },
    drawerPaperAnchorTop: {
        top: 64,
        right: 17,
    },
    drawerPaper: {
        display: 'block',
    },
    grow: {
        flexGrow: 1,
    },
    appBarButton: {
        color: '#ffffff',
        margin: theme.spacing(0.5),
    },
    appBar: {
        background: `url(${NavBarBackground})`,
        backgroundSize: 'cover',
    },
    gameDrawerContainer: {
        position: 'relative !important',
        zIndex: theme.zIndex.appBar - 1 + ' !important',
        marginTop: '250px !important',
    },

    logoImageStyle: {
        width: '80px',
        height: '64px',
        margin: '0px 5px 0px 0px',
    },

    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(9),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(10),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    loginButton: {
        marginLeft: '10px'
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    desktopMenu: {
        paddingLeft: '100px;',
    },

    desktopMenuButton: {
        fontFamily: theme.typography.logoFontFamily,
        fontSize: 14,
        minHeight: '64px',
        color: 'rgba(255, 255, 255, 0.7)',
        borderRadius: 0,
        textTransform: 'none',
        padding: '0px 15px 0px 15px',
        '&:hover': {
            color: 'white',
            textDecoration: 'none',
        },
        '&:active': {
            boxShadow: 'inset 0px -6px 0px 0px rgba(0,0,0,0.75);',
            color: '#ffffff',
        }
    },
    activeTab: {
        boxShadow: `inset 0px -6px 0px 0px ${theme.extendedProperties.accents.primary};`,
    },
    navLink: {
        textDecoration: 'none',
    },

    activeClassName: {
        boxShadow: 'inset 0 0 10px #0f0',
    },
    underConstruction: {
        position: 'absolute',
        fontSize: 8,
        bottom: 12,
        backgroundColor: 'rgba(147,92,223,.4)',//theme.extendedProperties.accents.primary,
        color: '#ffffff',
        borderRadius: 2,
        padding: '0px 4px 0px 4px',
        right: 0,
        fontFamily: theme.typography.logoFontFamily,
        fontWeight: 400,

    },
    brandText: {

    },
}));

const RenderMenu = (props) => {

    const classes = useStyles();
    const [state, dispatch] = useAppContext();

    return (
        <React.Fragment>
            <Menu
                anchorEl={state.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={state.profileMenuOpen}
                onClose={() => dispatch({type: 'TOGGLE_PROFILE_MENU'})}
            >
                <MenuItem onClick={() => console.log("TODO")}>Profile</MenuItem>
                <MenuItem onClick={() => console.log("TODO")}>My account</MenuItem>
            </Menu>

        </React.Fragment>
    )};

const RenderMobileMenu = (props) => {

    const classes = useStyles();
    const [state, dispatch] = useAppContext();
    
    return (
        <Menu
            anchorEl={state.mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={state.mobileMenuOpen}
            onClose={() => dispatch({type: 'CLOSE_MOBILE_MENU'})}>
            
            <MenuItem onClick={() => console.log("TODO")}>
                <IconButton color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem
                onClick={() => console.log("TODO")}>
                <IconButton color="inherit">
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
            <Button
                onClick={() => console.log("TODO")}
                variant="outlined">
                Log In
            </Button>
        </Menu>
    )
};

export function ApplicationAppBar() {

    const classes = useStyles();
    const [state, dispatch] = useAppContext();
    const navigate = useNavigate(); 
    /*
    useEffect(() => {
        async function getCategories() {
            try {
                const response = await fetch(
                    '/api/content/categories'
                );
                const data = await response.json();

                dispatch({ type: 'SET_CATEGORIES', value: data });
            } catch (e) {
                console.error(e);
            }
        }
        getCategories().then(promise => { });
    }, []);
    */

    const handleMicrosoftLogin = () => {
        navigate('/auth/login');
    };

    const handleMicrosoftRegister = () => {
        navigate('/auth/login');
    };
    
    return (
        <React.Fragment>
            <AppBar className={classes.appBar} position="relative" style={state.drawerOpen ? { paddingRight: 17 }: null}>
                <Toolbar className={classes.appBar}>

                    <NavLink to="/" exact={"true"} className={classes.navLink} activeClassName={classes.activeTab}>


                        <Button className={classes.desktopMenuButton + ' ' + classes.brandText} style={{textTransform: 'none', color: '#ffffff', fontSize: 18, marginLeft: 10, marginRight: 10,}}>
                            business <i style={{color:'#888888'}}>w</i>intelligence
                            <div className={classes.underConstruction}>BETA</div>
                        </Button>

                    </NavLink>

                    {
                    DIRECTORY_MAIN.map(dir => {
                        if (typeof(dir.requiredAction) == 'undefined') {
                            return (
                                (dir.showMenu) ?
                                    <NavLink key={dir.slug} to={"/" + dir.slug + "/"} className={classes.navLink} activeClassName={classes.activeTab}>
                                        <Button className={classes.desktopMenuButton}>
                                            {dir.title.toUpperCase()}
                                        </Button>
                                    </NavLink> : ''
                            )}
                        else {
                            
                            if (typeof(state.allowedActions) !== 'undefined' 
                                && Array.isArray(state.allowedActions)
                                && state.allowedActions.some(action => action.actionName === dir.requiredAction)) {
                                return (
                                    (dir.showMenu) ?
                                        <NavLink key={dir.slug} to={"/" + dir.slug + "/"} className={classes.navLink} activeClassName={classes.activeTab}>
                                            <Button className={classes.desktopMenuButton}>
                                                {dir.title.toUpperCase()}
                                            </Button>
                                        </NavLink> : ''
                                )}
                            
                            }
                        })
                    }

                    <div className={classes.grow} />

                    {/*
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                        />
                    </div>
                    <Button
                         onClick={() => dispatch({type: 'TOGGLE_DRAWER'})}
                        className={classes.appBarButton}
                    >
                        O
                    </Button>
                    */}
                    
                    <div className={classes.sectionDesktop}>
                        {state.authenticated &&
                        <React.Fragment>
                            
                            <IconButton 
                                color="inherit"
                                onClick={(event) => dispatch({type: 'TOGGLE_USER_MENU', value: event.currentTarget })}>
                                <AccountCircle/>
                            </IconButton>
                            
                            <UserMenu/>
                            
                        </React.Fragment>
                        }

                        {!state.authenticated &&
                        <React.Fragment>
                            <Button
                                href={'/auth/login/'}
                                className={classes.appBarButton}
                            >
                                    <span>
                                        Login
                                    </span>
                            </Button>

                            <Button
                                href={'/auth/login/'}
                                className={classes.appBarButton}
                                variant="outlined"
                            >
                                Sign Up
                            </Button>
                        </React.Fragment>
                        }
                    </div>

                    <AuthenticationModal/>

                    <div className={classes.sectionMobile}>
                        <IconButton aria-haspopup="true" /* onClick={() => dispatch({type: OPEN_MOBILE_MENU})}*/ color="inherit">
                            <MoreIcon />
                        </IconButton>
                    </div>


                </Toolbar>
            </AppBar>

            <RenderMenu/>
            <RenderMobileMenu/>

            <SwipeableDrawer
                anchor="top"
                open={state.drawerOpen}
                onClose={() => { dispatch({ type: 'CLOSE_DRAWER' })}}
                classes={{ root: classes.drawerRoot, modal: classes.drawerModal, paper: classes.drawerPaper, paperAnchorTop: classes.drawerPaperAnchorTop}}
            >
                <MainDrawerContents
                    drawerType={state.drawerType} />
            </SwipeableDrawer>



        </React.Fragment>
    );
};


