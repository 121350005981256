import React, {useEffect, useState} from 'react';

import {
    FaHeading,
    FaListOl,
    FaListUl,
    FaCode,
    FaQuoteRight,
    FaBold, 
    FaItalic, 
    FaUnderline,
    FaHighlighter,
    FaFileVideo,
    FaVideo,
    FaFileImage
} from 'react-icons/fa';
import {useEditorContext} from "../EditorProvider";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover/Popover";
import Input from "@material-ui/core/Input/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import {BLOB_STORAGE_URI} from "../../constants/Constants";
import IconButton from "@material-ui/core/IconButton";
import {
    BlockquoteButton,
    BoldButton, CodeBlockButton,
    CodeButton, HeadlineOneButton, HeadlineThreeButton, HeadlineTwoButton,
    ItalicButton,
    OrderedListButton,
    UnderlineButton,
    UnorderedListButton
} from "draft-js-buttons";

import { plugins, LinkButton, inlineToolbarPlugin, videoPlugin, imagePlugin } from '../plugins';
import {Separator} from "../plugins/custom-inline-toolbar-plugin/src/components/Separator";

const useStyles = makeStyles((theme) => createStyles({

    editorContainer: {
        width: '100%',
        minHeight: 250,
        boxSizing: 'border-box',
        border: '2px solid #ddd',
        cursor: 'text',
        padding: 16,
        borderRadius: 2,
        marginBottom: '2em',
        background: '#fefefe'
        //boxShadow: 'inset 0px 1px 1px -1px #ABABAB',
    },

    addVideoPopover: {
      padding: 15,  
    },
    
    addImagePopover: {
        padding: 15,
    },

    editor: {
        cursor: 'text',
        padding: 16,
        marginBottom: '2em',
    },

    publicDraftEditorContent: {
        minHeight: 140
    },

    headlineButtonWrapper: {
        display: 'inline-block',
    },

    headlineButton: {
        background: '#fbfbfb',
        color: '#888',
        fontSize: 18,
        border: 0,
        paddingTop: 5,
        verticalAlign: 'bottom',
        height: 36,
        width: 36
    },

    toolbar: {
        display: 'flex',
        flexWrap: 'wrap',
        borderBottom: 'solid 1.7px rgba(199, 198, 255, 0.15)',
        padding: '10px 0',
        margin: '0 0 10px 0',
        '& button' : {
            height: 40,
            padding: 0,
            minWidth: 40,
            transition: '0.1s ease-in',
            '&.active': {
                backgroundColor: '#007bff',
                color: 'white'
            }
        }
    },

    button: {
        background: '#fbfbfb',
        color: '#888',
        fontSize: 18,
        border: 0,
        paddingTop: 5,
        verticalAlign: 'bottom',

        "&:active": {
            color: '#000000',
        },
        "&:active svg": {
            fill: '#6a9cc9',
        },


    }

}));

const StyleButton = ({
                        active,
                        toggleBlock,
                        toggleStyle,
                        style,
                        label
                     }) => {
    
    const toggleActiveBlock = (e) => {
        e.preventDefault();
        toggleBlock(style);
    };
    
    const classes = useStyles();
    const [state, dispatch] = useEditorContext();
    
    return (
        <button type="button"
                className={classes.button}
                onMouseDown={toggleActiveBlock}>
            {label}
        </button>
    );
};

const BLOCK_TYPES = [
    {
        label: (
            <React.Fragment>
                <FaHeading />
                <strong>
                    <sub>1</sub>
                </strong>
            </React.Fragment>
        ),
        style: 'header-one'
    },
    {
        label: (
            <React.Fragment>
                <FaHeading />
                <strong>
                    <sub>2</sub>
                </strong>
            </React.Fragment>
        ),
        style: 'header-two'
    },
    {
        label: (
            <React.Fragment>
                <FaHeading />
                <strong>
                    <sub>3</sub>
                </strong>
            </React.Fragment>
        ),
        style: 'header-three'
    },
    {
        label: (
            <React.Fragment>
                <FaHeading />
                <strong>
                    <sub>4</sub>
                </strong>
            </React.Fragment>
        ),
        style: 'header-four'
    },
    { label: <FaQuoteRight />, style: 'blockquote' },
    { label: <FaListUl />, style: 'unordered-list-item' },
    { label: <FaListOl />, style: 'ordered-list-item' },
    { label: <FaCode />, style: 'code-block' },
];

const INLINE_STYLES = [
    { label: <FaBold />, style: 'BOLD' },
    { label: <FaItalic />, style: 'ITALIC' },
    { label: <FaUnderline />, style: 'UNDERLINE' },
    { label: <FaCode />, style: 'CODE' },
    { label: <FaHighlighter />, style: 'HIGHLIGHT' }
];

export const AddImage = () => {
    
    const classes = useStyles();
    const [state, dispatch] = useEditorContext();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [url, setUrl] = useState('');
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const addImage = () => {
        handleClose();
        dispatch({type: 'SET_EDITOR_STATE', value: imagePlugin.addImage(state.editorState,  url )});
    };

    const changeUrl = (evt) => {
        setUrl(evt.target.value);
    };
    
    const attachmentClick = (value) => {
        dispatch({type: 'SET_EDITOR_STATE', value: imagePlugin.addImage(state.editorState,  value )});
        handleClose();
    };
    
    const AttachmentList = () => {
        
        if (typeof(state.attachments) !== 'undefined' && state.attachments !== null && state.attachments.length > 0) {
            
            return (
                <React.Fragment>
                    {state.attachments.map((attachment, index) => {
                        const attachmentUri = BLOB_STORAGE_URI + attachment.uri;
                        return (
                            <Button 
                                key={index} 
                                onClick={e => attachmentClick(attachmentUri)}
                            >
                                <div>{attachment.filename}</div>
                            </Button>
                        )
                    }
                        
                    )}
                </React.Fragment>
            )
        }
        else {
            return null;
        }
    };

    return (
        <div>
            <ToggleButton
                onClick={handleClick}
                value='add-image'
            >
                <FaFileImage/>
            </ToggleButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.addImagePopover} >

                    <Input
                        onChange={changeUrl}
                        placeholder="Enter Image URL"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="submit image url"
                                    onClick={addImage}
                                >
                                    <FaFileImage/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <div>
                        <AttachmentList/>
                    </div>
                </div>
            </Popover>
        </div>
    );
}

export const InlineControl = (props) => {
    
    const { InlineToolbar } = inlineToolbarPlugin;
    const {state, dispatch, editorRef} = props;

    function getEditorState() {
        const returnState = state.editorState;
        return (returnState);
    }
    
    function setEditorState(desiredState) {
        dispatch({type: 'SET_EDITOR_STATE', value: desiredState});
    }
    function getEditorRef() {
        return (editorRef)
    }
    
    return (
        <InlineToolbar  >
            {
                (externalProps) => (
                    <React.Fragment>
                        <BoldButton {...externalProps} />
                        <ItalicButton {...externalProps} />
                        <UnderlineButton {...externalProps} />
                        <CodeButton {...externalProps} />
                        <Separator {...externalProps} />
                        <UnorderedListButton {...externalProps} />
                        <OrderedListButton {...externalProps} />
                        <BlockquoteButton {...externalProps} />
                        <CodeBlockButton {...externalProps} />
                    </React.Fragment>
                )
            }
        </InlineToolbar>
    )
};

export const AddVideo = () => {
    
    const classes = useStyles();
    const [state, dispatch] = useEditorContext();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [url, setUrl] = useState('');
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const addVideo = () => {
        handleClose();
        dispatch({type: 'SET_EDITOR_STATE', value: videoPlugin.addVideo(state.editorState, { src: url })});
    };

    const changeUrl = (evt) => {
        setUrl(evt.target.value);
    };

    return (
        <div>
            <ToggleButton
                onClick={handleClick}
                value={'add-video'}
            >
                <FaVideo/>
            </ToggleButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.addVideoPopover} >

                    <Input
                        onChange={changeUrl}
                        placeholder="Enter Video URL"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="submit video url"
                                    onClick={addVideo}
                                >
                                    <FaFileVideo/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </div>
            </Popover>
        </div>
    );
}

export const HeadlinesPicker = (props) => {

    useEffect(() => {

        setTimeout(() => { window.addEventListener('click', props.onWindowClick); });

        return function cleanup() {
            window.removeEventListener('click', props.onWindowClick);
        }

    }, []);

    props.onWindowClick = () =>
        // Call `onOverrideContent` again with `undefined`
        // so the toolbar can show its regular content again.
        props.onOverrideContent(undefined);

    const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];
    return (
        <div>
            {buttons.map((Button, i) => // eslint-disable-next-line
                <Button key={i} {...props} />
            )}
        </div>
    );
};

export const HeadlinesButton = (props) => {
    // When using a click event inside overridden content, mouse down
    // events needs to be prevented so the focus stays in the editor
    // and the toolbar remains visible  onMouseDown = (event) => event.preventDefault()
    props.onMouseDown = (event) => event.preventDefault();

    props.onClick = () =>
        // A button can call `onOverrideContent` to replace the content
        // of the toolbar. This can be useful for displaying sub
        // menus or requesting additional information from the user.
        props.onOverrideContent(HeadlinesPicker);

    return (
        <div onMouseDown={props.onMouseDown}>
            <button onClick={props.onClick}>
                H
            </button>
        </div>
    );
};

export const StyleControls = ({ toggleBlock, toggleStyle, classList }) => {

    const [state, dispatch] = useEditorContext();
    const classes = useStyles();
    
    const selection = state.editorState.getSelection();
    const blockType = state.editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    const currentStyle = state.editorState.getCurrentInlineStyle();
    
    //console.log("CURRENT BLOCK TYPE: " + blockType);

    return (
        <Grid container 
              spacing={2} 
              direction="column">
            <Grid item>
                <div className={classes.toolbar}>
                    <ToggleButtonGroup 
                        size="small"
                        exclusive
                        aria-label="text alignment"
                    >
                        {BLOCK_TYPES.map((type) => {

                            const toggleActive = (e) => {
                                e.preventDefault();
                                toggleBlock(type.style);
                            };
                            
                            return(
                            <ToggleButton
                                key={type.style}
                                aria-label="left aligned"
                                selected={type.style === blockType}
                                label={type.label}
                                value={type.style}
                                onMouseDown={toggleActive}
                            >
                                {type.label}
                            </ToggleButton>
                            )
                        })}
                    </ToggleButtonGroup>
                    
                    <ToggleButtonGroup
                        size="small"
                        exclusive
                        aria-label="text alignment"
                        style={{marginLeft: 10}}
                    >
                        {INLINE_STYLES.map((type, index) => {

                            const toggleActive = (e) => {
                                e.preventDefault();
                                toggleStyle(type.style);
                            };
                            
                            return(
                                <ToggleButton
                                    key={type.style}
                                    selected={currentStyle.has(type.style)}
                                    aria-label="left aligned"
                                    label={type.label}
                                    value={type.style}
                                    onMouseDown={toggleActive}
                                >
                                    {type.label}
                                </ToggleButton>
                            )
                        })}
                    </ToggleButtonGroup>

                    <ToggleButtonGroup
                        size="small"
                        exclusive
                        aria-label="text alignment"
                        style={{marginLeft: 10}}
                    >
                        <AddVideo/>
                        <AddImage/>
                    </ToggleButtonGroup>

                </div>
            </Grid>
        </Grid>
    );
};
