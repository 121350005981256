import React from 'react';
import linkifyIt from 'linkify-it';

export const LINK_REGEX = /(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?/;
export const HASHTAG_REGEX = /(#[A-Z0-9a-zÀ-ÖØ-öø-ÿ]+)/g;
const linkify = linkifyIt();

function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr;
    let start;
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
    }
}

const linkStrategy = (contentBlock: Object, callback: Function) => {
    const links = linkify.match(contentBlock.getText());
    if (typeof links !== 'undefined' && links !== null) {
        links.forEach((link) => callback(link.index, link.lastIndex));
    }
};

const LinkComponent = ({ children }, props) => (
    <a {...props} className="link" href={children}>
        {children}
    </a>
);

function hashtagStrategy(block, callback, state) {
    findWithRegex(HASHTAG_REGEX, block, callback);
}

const HashtagComponent = ({ children }, props) => (
    <span {...props} className="hashtag text-muted">
    {children}
  </span>
);

export const decorators = [
    {
        strategy: linkStrategy,
        component: LinkComponent
    },
    {
        strategy: hashtagStrategy,
        component: HashtagComponent
    }
];

export default decorators;