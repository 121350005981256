import React from "react";
import GameScroller from "./../../components/GameScroller";

const MainDrawerContents = props => {
  const { drawerType } = props;
  return (
    <React.Fragment>
      {drawerType === "Guild" && <GameScroller />}
      {drawerType === "Game" && <GameScroller />}
      {drawerType === "Community" && <GameScroller />}
      {drawerType === "Marketplace" && <GameScroller />}
      {drawerType === "Support" && <GameScroller />}
    </React.Fragment>
  );
};

export default MainDrawerContents;
