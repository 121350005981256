import React from 'react';
import { css } from '@emotion/core';
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => createStyles({
    separator: {
        display: 'inline-block',
        borderRight: '1px solid #ddd',
        height: 24,
        margin: '0 0.5em',
    },
}));

export const Separator = () => {
    const classes = useStyles();
    return (
        <div className={classes.separator} />
    )
}

