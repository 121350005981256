import { createMuiTheme } from "@material-ui/core";

export const lightTheme = {
    typography: {
        logoFontFamily: [
            'Work Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        headerFontFamily: [
            'Open Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        bodyFontFamily: [
            'Open Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontFamily: [
            'Work Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        type: 'light',//theme.paletteType,
        background: {
            primary: '#212121',//'#282a36',//theme.paletteType === 'light' ? '#000' : '#fff',
            secondary: '#292d3e',
            tertiary: '#4e5579',
        },
        primary: {
            main: '#111111',//'#282a36',
            light: '#292d3e',  //#4527a0
            dark: '#4e5579',
            contrastText: 'rgba(255,255,255, 0.9)',
            textColor: '#3a3a3a',
            fadedText: 'rgba(255,255,255, 0.2)',
            linkColor: '#7f6cb8',
        },
        secondary: {
            main: '#0fb28e', // 625aab       // orange e6684a    green 7ec980
            light: '#0fb28e',  // 8079e9
            contrastText: "#fff",
            textColor: "white",
            alternateTextColor: "white",
        },
    },
    zIndex: {
        mobileStepper: 1000,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 0,    
            },
            
        },
        MuiCard: {
            root: {
                borderRadius: 0,
                //boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
                boxShadow: 'none',
            },
        },
        MuiDialog: {
            paper: {
                borderRadius: 0,
            }
        },
        MuiPaper: {
            root: {
                borderRadius: 0,
            }
        }
    },
    extendedProperties: {
        accents: {
            primary: '#945ee5', //#945ee5
            secondary: '#90e76c',
            tertiary: '#82aaff',
            fourth: '#f68b54',
        },
        background: {
            primary: '#ffffff',
        },
        foreground: {
            primary: '#ffffff',
        },
        grayscale: {
            primary: '#999999',
        },
        paper: {
            primary: '#ffffff',
            secondary: '#ffffff',
        },
    }
};

export const darkTheme = lightTheme; /*{
    
    
    typography: {
        
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },

    /*  222533 - darker navy blue
     *  #292d3e - dark navy blue
     *  #c792ea - light purple
     *  #7ec980 - light green
     *  #e6684a - orangered
     */
    /*
    palette: {
        
        type: 'light',//theme.paletteType,
        background: {
            default: '#292d3e',//theme.paletteType === 'light' ? '#000' : '#fff',
        },
        primary: {
            main: '#7ec980',
            light: '#292d3e',
            
        },
        secondary: {
            main: '#a163c9', // 625aab       // orange e6684a    green 7ec980
            light: '#c792ea',  // 8079e9
            
        },
    },
    zIndex: {
        mobileStepper: 1000,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
   
    
}; */




export const createLocalTheme = (themeType) => {
    
    if (themeType === 'dark') {

        let tempTheme = createMuiTheme(darkTheme);
        return(tempTheme);
    }
    else {
        let tempTheme = createMuiTheme(lightTheme);
        return(tempTheme);
    }
    
};

export default createLocalTheme;