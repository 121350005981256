import {css} from "@emotion/core";
import PuffLoader from "react-spinners/PuffLoader";
import React from "react";

const override = css`
  position: fixed;
  top: 20%;
  left: 50%;
  display: block;
  margin: 0 auto;
  border-color: #0fb28e;
`;

export const OverlaySpinner = (props) => {
    
    //BeatLoader
    
    return (
        <PuffLoader
            css={override}
            size={200}
            color={"#0fb28e"}
            loading={props.loading}
        />
    )
};
