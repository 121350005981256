import {useAppContext} from "../../context/Provider";
import {useNavigate} from "react-router";
import React, {useEffect} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {createStyles, ListItemIcon, ListItemText} from "@material-ui/core";
import {EditIcon} from "../../icons/Icons";
import Moment from "react-moment";
import {DeleteOutline as DeleteIcon, Publish as PublishIcon} from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => createStyles({

    table: {

    },

}));


export const AdministrationPublished = (props) => {

    const [state, dispatch] = useAppContext();
    const navigate = useNavigate();

    async function GetPublished() {
        useEffect(() => {
            async function GetPublished() {

                const apiUri = `/api/content/get-list?authorId=${state.userId}&published=1`;
                let options= {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    //body: JSON.stringify(data),
                    referrer: 'no-referrer',
                };

                if (state.userId > 0) {
                    try {
                        dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: true });
                        const response = await fetch(apiUri, options);
                        const data = await response.json();
                        setTableData(data);

                    } catch (e) {
                        console.error(e);
                    }
                    finally {
                        dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: false });
                    }
                }
            }
            GetPublished().then(promise => { });
        }, [state.userId]);
    }
    GetPublished();
    const classes = useStyles();
    const [tableData, setTableData] = React.useState([{}]);
    return (
        <React.Fragment>
            <h1>Published</h1>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Item</TableCell>
                        <TableCell align="right">Modified</TableCell>
                        <TableCell align="right">Created</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell align="left">
                                <ListItem button component="a" onClick={() => navigate(`/administration/edit/${row.itemId}`)}>
                                    <ListItemIcon><EditIcon/></ListItemIcon>
                                    <ListItemText primary={row.title} />
                                </ListItem>
                            </TableCell>
                            <TableCell align="right"><Moment format="MM/DD/YYYY HH:mm">{row.modifiedDtm}</Moment></TableCell>
                            <TableCell align="right"><Moment format="MM/DD/YYYY HH:mm">{row.createdDtm}</Moment></TableCell>
                            <TableCell align="center">
                                <Button>
                                    <DeleteIcon/>
                                </Button>

                                <Button>
                                    <PublishIcon/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    )
};