export const initialState = {
    authenticationModal: {open: false, tab: 'login'},
    anchorEl: null,
    mobileMoreAnchorEl: null,
    userMenuOpen: false,
    isMenuOpen: false,
    mobileMenuOpen: false,
    authModalTab: 'Login',
    authModalOpen: false,
    drawerType: 'Support',
    drawerOpen: false,
    profileMenuOpen: false,
    authenticated: false,
    identifier: '',
    userId: 0,
    username: '',
    email: '',
    allowedActions: {},
    activeElement: null,
    contentList: [],
    administrationLoading: false,
    title: 'Business Wintelligence'
};