import {useAppContext} from "../../context/Provider";
import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {createStyles, ListItemIcon, ListItemText} from "@material-ui/core";
import {EditIcon} from "../../icons/Icons";
import Moment from "react-moment";
import {DeleteOutline as DeleteIcon, Publish as PublishIcon} from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => createStyles({
    table: {
        
    },
}));

const NewCategory = (props) => {
    const [inputCategory, setInputCategory] = useState([]);
    const handleInputCategory = (event) => {
        setInputCategory(event.target.value);
    };

    async function SubmitCategories() {
        let apiUri = `/api/content/set-category?categoryId=-1&category=${inputCategory}`;
        let options= {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            referrer: 'no-referrer',
        };
        try {
            await fetch(apiUri, options);
        } catch (e) {
            console.error(e);
        }
    }
    
    const handleSubmit = () => {
        if (inputCategory.length > 1) {
            SubmitCategories();
            props.onClose();
        }
        else {
            alert("Invalid Category!");
        }
    };
    
    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">New Category</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This will permanently add a new main category. Use caution.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Category"
                    type="text"
                    fullWidth
                    value={inputCategory}
                    onChange={handleInputCategory}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
};

const NewSubCategory = (props) => {
    const [state, dispatch] = useAppContext();
    const [inputParentCategoryId, setInputParentCategoryId] = useState(0);
    const [inputSubCategory, setInputSubcategory] = useState('');
    
    async function SubmitSubCategories() {
        let apiUri = `/api/content/set-subcategory?categoryId=${inputParentCategoryId}&subcategory=${inputSubCategory}`;
        let options= {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            referrer: 'no-referrer',
        };
        try {
            await fetch(apiUri, options);
        } catch (e) {
            console.error(e);
        }
    }
    
    const handleInputCategoryId = (event) => {
        setInputParentCategoryId(event.target.value);
    };

    const handleInputSubcategory = (event) => {
        setInputSubcategory(event.target.value);
    };

    const handleSubmit = () => {
        if (inputSubCategory.length > 1 && inputParentCategoryId > 0) {
            SubmitSubCategories();
            props.onClose();
        }
        else {
            alert("Invalid SubCategory Submission!");
        }
    };
        
        
    
    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">New SubCategory</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This will permanently add a new subcategory. Use caution.
                </DialogContentText>

                <FormControl fullWidth={true}>
                    <InputLabel>Category</InputLabel>
                    <Select
                        value={inputParentCategoryId}
                        onChange={handleInputCategoryId}
                    >
                        {
                            state.categories.map((category, index) => {
                                return (
                                    <MenuItem key={index} value={category.categoryId}>{category.category}</MenuItem>
                                )})
                        }
                    </Select>
                </FormControl>

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="SubCategory"
                    type="text"
                    fullWidth
                    value={inputSubCategory}
                    onChange={handleInputSubcategory}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export const AdministrationCategories = (props) => {

    const [state, dispatch] = useAppContext();
    const [categoryDetail, setCategoryDetail] = useState([]);
    const classes = useStyles();

    // Input Category Variable
    const [newCategoryOpen, setNewCategoryOpen] = useState(false);

    // Input SubCategory Variables
    const [newSubCategoryOpen, setNewSubCategoryOpen] = useState(false);
    
    useEffect(() => {
        
        async function getCategoryDetail() {
            let apiUri = "/api/content/category-detail";
            let options= {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                referrer: 'no-referrer',
            };

            try {
                dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: true });
                const response = await fetch(apiUri, options);
                const data = await response.json();
                setCategoryDetail(data);
            } catch (e) {
                console.error(e);
            }
            finally {
                dispatch({ type: 'SET_ADMINISTRATION_LOADING', value: false });
            }

        }
        getCategoryDetail().then(promise => { });
    }, []);

    return (
        <React.Fragment>
            <NewCategory open={newCategoryOpen} onClose={() => setNewCategoryOpen(false)}/>
            <NewSubCategory open={newSubCategoryOpen} onClose={() => setNewSubCategoryOpen(false)}/>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div>
                    <h1>Categories</h1>
                </div>
                <div style={{flexGrow: 1}}>
                </div>
                <div style={{alignSelf: 'center'}}>
                    <Button variant="contained" color="primary" 
                            onMouseDown={() => { setNewCategoryOpen(true)}} open={newCategoryOpen}>New Category</Button>
                    <Button style={{marginLeft: 10}} variant="contained" color="primary" 
                            onMouseDown={() => { setNewSubCategoryOpen(true)}} open={newSubCategoryOpen}>New SubCategory</Button>
                </div>
            </div>

            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Edit</TableCell>
                        <TableCell align="left">Category</TableCell>
                        <TableCell align="right">SubCategory</TableCell>
                        <TableCell align="right">Created</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categoryDetail.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell align="left">
                                <ListItem button component="a" href={`/administration/edit/${row.itemId}`}>
                                    <ListItemIcon><EditIcon/></ListItemIcon>
                                    <ListItemText primary={row.title} />
                                </ListItem>
                            </TableCell>
                            <TableCell align="right">{row.category}</TableCell>
                            <TableCell align="right">{row.subCategory}</TableCell>
                            <TableCell align="right"><Moment format="MM/DD/YYYY HH:mm">{row.createdDtm}</Moment></TableCell>
                            <TableCell align="center">
                                <Button>
                                    <DeleteIcon/>
                                </Button>

                                <Button>
                                    <PublishIcon/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </React.Fragment>
    )
};